<template>
  <v-container fluid>
    <v-app>
      <AppSnackbar
        :visible="snackbar"
        snackbarColor="error"
        :text="snackText"
        icon="report_problem"
      />

      <AppSnackbar
        :visible="snackbarInfo"
        snackbarColor="primary"
        :text="snackTextInfo"
        icon="info"
      />

      <div v-if="isUserLogged">
        <v-navigation-drawer
          :clipped="clipped"
          v-model="drawer"
          fixed
          app
          :mobile-breakpoint="960"
          width="220"
          class="nav-menu"
          @input="drawerTransitioned"
        >
          <v-list>
            <v-list-item
              v-for="item in navigationMenu()"
              :key="item.title"
              :to="item.to"
            >
              <v-list-item-action>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  <v-layout align-center>
                    {{ item.title }}
                    <v-chip
                      class="v-chip-small"
                      style="margin-left: auto"
                      label
                      color="orange"
                      text-color="white"
                      v-if="item.beta"
                      >BETA</v-chip
                    >
                  </v-layout>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-navigation-drawer>

        <!-- Component for About Dialog -->
        <AboutDialog v-model="aboutDialog" />
        <RolesInfo v-model="openRoles" />

        <v-app-bar
          dense
          color="blue darken-4"
          dark
          fixed
          app
          :clipped-left="clipped"
        >
          <v-app-bar-nav-icon @click.stop="click()" />

          <v-img
            :src="imgSrcToolbar"
            class="hidden-xs-only"
            alt="BLUE"
            position="center left"
            contain
            height="26px"
          />

          <v-layout fluid align-center justify-end>
            <v-menu
              offset-y
              left
              scroll-off-screen="false"
              transition="slide-y-transition"
            >
              <template v-slot:activator="{ on }">
                <div v-on="on">
                  <v-badge
                    color="cyan"
                    right
                    bottom
                    style="top: 1px"
                    v-if="pendingNotificationsCount > 0"
                  >
                    <template v-slot:badge>
                      <span>{{ pendingNotificationsCount }}</span>
                    </template>
                    <v-icon style="cursor: pointer">account_circle</v-icon>
                  </v-badge>
                  <v-icon v-else>account_circle</v-icon>
                </div>
              </template>

              <NotificationsMenu v-if="pendingNotificationsCount > 0" />
            </v-menu>

            <span class="username hidden-xs-only px-2" @click="showRoles()">{{
              user.toUpperCase()
            }}</span>
            <v-menu
              offset-y
              left
              :close-on-content-click="false"
              transition="slide-y-transition"
              scroll-off-screen="false"
              content-class="header-options-menu"
              v-model="menuOpen"
            >
              <template v-slot:activator="{ on }">
                <v-btn dark icon v-on="on">
                  <v-icon>expand_more</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-group v-model="languageMenu" no-action>
                  <v-list-item class="pl-1" slot="activator">
                    <v-list-item-action>{{
                      languageSelected
                    }}</v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>{{
                        $t("menu.language")
                      }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    v-for="lang in languages"
                    :key="lang.to"
                    @click="changeLanguage(lang.to)"
                  >
                    <v-list-item-action>{{ lang.lang }}</v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>{{ lang.text }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-group>
                <v-list-item @click="showAbout()">
                  <v-list-item-action>
                    <v-icon>info</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>{{
                      $t("menu.about")
                    }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item @click="logout()">
                  <v-list-item-action>
                    <v-icon>exit_to_app</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>{{
                      $t("menu.logout")
                    }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-layout>
        </v-app-bar>
      </div>
      <router-view />
    </v-app>
  </v-container>
</template>

<script>
import { mapMutations } from "vuex";

import { goTo } from "@/router";
import AppSnackbar from "@/components/AppSnackbar";
import AboutDialog from "@/components/AboutDialog";
import { defineAbilitiesFor } from "@/casl";
import languagesMixin from "@/mixins/languages.mixin";
import sessionStorageMixin from "@/mixins/session.storage.mixin";

import menu from "./menu";

import RolesInfo from "@/components/RolesInfo/RolesInfo";

import NotificationsMenu from "@/components/NotificationsMenu/NotificationsMenu";
const keyView = "Open_Menu";

import esLocDevExtreme from "devextreme/localization/messages/es.json";
import { locale, loadMessages } from "devextreme/localization";
import LanguagesHelper from "./helpers/languagesHelper";

export default {
  name: "Mavi",
  components: {
    AppSnackbar,
    AboutDialog,
    NotificationsMenu,
    RolesInfo,
  },

  mixins: [languagesMixin, sessionStorageMixin],

  data() {
    return {
      drawer: false,
      clipped: true,
      openRoles: false,
      menuOpen: false,
      languageMenu: false,
      aboutDialog: false,
    };
  },
  computed: {
    isUserLogged() {
      return this.$store.state.isUserLogged;
    },
    user() {
      return this.$store.state.user;
    },
    languageSelected() {
      if (this.languages) {
        const langValue = this.languages.find(
          (lang) => lang.to === this.$route.params.lang
        );
        if (langValue) {
          return langValue.lang;
        }
      }
      return process.env.VUE_APP_I18N_LOCALE;
    },

    languages() {
      return [
        {
          text: this.getLanguageName("en"),
          lang: "EN",
          to: "en",
        },
        {
          text: this.getLanguageName("es"),
          lang: "ES",
          to: "es",
        },
      ];
    },
    snackbar() {
      return this.$store.state.snackbar;
    },
    snackText() {
      return this.$store.state.snackText;
    },
    snackbarInfo() {
      return this.$store.state.snackbarInfo;
    },
    snackTextInfo() {
      return this.$store.state.snackTextInfo;
    },
    imgSrcToolbar() {
      return process.env.VUE_APP_PUBLIC_PATH + "assets/blue_toolbar.png";
    },
    pendingNotificationsCount() {
      return this.$store.getters["pendingNotificationsCount"];
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.drawer =
        sessionStorage.getItem(keyView) !== null
          ? this.session_getItemBoolean(keyView)
          : this.$vuetify.breakpoint.smAndUp
          ? true
          : false;
    });

    this.$vuetify.lang.current = this.$route.params.lang;
    this.$ability.update(defineAbilitiesFor());

    loadMessages(esLocDevExtreme);
    locale(this.$route.params.lang);
    LanguagesHelper.getInstance().setLanguage(this.$route.params.lang);
  },

  methods: {
    ...mapMutations(["setUserLogout"]),

    drawerTransitioned(event) {
      this.session_setItem(keyView, event);
    },

    logout() {
      this.setUserLogout();
      this.menuOpen = false;

      goTo({ name: "Login" });
    },

    changeLanguage(newLanguage) {
      const currentLanguage = this.$route.params.lang;
      if (currentLanguage === newLanguage) {
        return;
      }

      const route = Object.assign({}, this.$route);
      route.params.lang = newLanguage;
      this.$vuetify.lang.current = newLanguage;

      locale(newLanguage);
      loadMessages(newLanguage);
      LanguagesHelper.getInstance().setLanguage(newLanguage);

      this.menuOpen = false;
      this.languageMenu = false;

      goTo(route);
    },

    click() {
      this.drawer = !this.drawer;
    },

    showAbout() {
      this.aboutDialog = true;
      this.menuOpen = false;
    },
    showRoles() {
      this.openRoles = true;
    },

    getMenuItem(item) {
      return {
        title: this.$t(item.title),
        icon: item.icon,
        to: item.to,
        beta: item.beta,
      };
    },
    navigationMenu() {
      const authorizedMenu = menu.map((item) => {
        if (this.$ability.can("view", item.to.name)) {
          return this.getMenuItem(item);
        } else {
          return null;
        }
      });

      return authorizedMenu.filter((x) => x !== null);
    },
  },
};
</script>

<style scope>
.username {
  font-size: 14px;
}

.v-toolbar__content .v-badge__badge {
  margin-right: 14px;
  margin-bottom: 10px;
  font-size: 10px;
  width: 18px;
  height: 18px;
  cursor: pointer;
}

.container {
  padding: 0px !important;
}

#menuButton {
  padding: 0px;
}

.v-list__tile--link .v-list__tile__content {
  color: #666666dd;
}

.v-list__tile--active .v-list__tile__content {
  color: #333333;
}
</style>
