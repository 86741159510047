import Vue from "vue";
import Component from "vue-class-component";

import { Prop } from "vue-property-decorator";
import DxfDataGrid from "../../../../components/DxfDataGrid/DxfDataGrid.vue";
import { translateText } from "@/lang/i18n";

import { Column } from "devextreme/ui/data_grid";

@Component({
  components: {
    DxfDataGrid,
  },
})
export default class InstallationDetailsTable extends Vue {
  // eslint-disable-next-line
  @Prop() dataArray!: Array<any>;
  @Prop({ default: false }) loading!: boolean;
  // eslint-disable-next-line
  @Prop() menuOptions!: Array<any>;

  showExport = true;

  title = translateText("menu.installationDetails");

  get columns(): Column[] {
    const allowedOperations = ["contains", "="];
    const columns: Column[] = [
      {
        dataField: "device",
        caption: translateText("device.devices"),
        filterOperations: allowedOperations,
      },
      {
        dataField: "model",
        caption: translateText("device.model"),
        filterOperations: allowedOperations,
      },
      {
        dataField: "direccionBloque",
        caption: translateText("device.addressBlock"),
        alignment: "left",
        filterOperations: allowedOperations,
      },
      {
        dataField: "direccionSubBloque",
        caption: translateText("device.addressSubBlock"),
        alignment: "left",
        filterOperations: allowedOperations,
      },
      {
        dataField: "direccionVivienda",
        caption: translateText("device.address"),
        alignment: "left",
        filterOperations: allowedOperations,
      },
      {
        dataField: "name",
        caption: translateText("installation.tag"),
        filterOperations: allowedOperations,
      },
      {
        dataField: "versionHW",
        caption: translateText("general.hardware"),
      },
      {
        dataField: "version",
        caption: translateText("general.version"),
        alignment: "center",
        filterOperations: allowedOperations,
      },
      {
        dataField: "build",
        caption: translateText("general.build"),
        alignment: "center",
        filterOperations: allowedOperations,
      },
      {
        dataField: "id",
        caption: translateText("installation.id"),
        filterOperations: allowedOperations,
      },
      {
        dataField: "callDivertServiceEnabled",
        caption: translateText("installationDetails.callDivertServiceEnabled"),
        filterOperations: allowedOperations,
        allowFiltering: false,
        allowHeaderFiltering: false,
        cellTemplate: "cellCheck",
      },
      {
        dataField: "estate",
        caption: translateText("general.status"),
        filterOperations: allowedOperations,
        allowFiltering: false,
        allowHeaderFiltering: false,
        alignment: "left",
        cellTemplate: "cellStatusChip",
      },
      {
        dataField: "coverage",
        caption: translateText("installation.coverage"),
        filterOperations: allowedOperations,
      },
      {
        cellTemplate: "menu",
        width: 60,
        allowExporting: false,
      },
    ];

    return columns;
  }
}
