import countries from "i18n-iso-countries";
import es from "i18n-iso-countries/langs/es.json";
import en from "i18n-iso-countries/langs/en.json";
import LanguagesHelper from "@/helpers/languagesHelper";

export default class i18nCountryHelper {
  private static instance: i18nCountryHelper;

  private constructor() {
    countries.registerLocale(es);
    countries.registerLocale(en);
  }

  public static getInstance(): i18nCountryHelper {
    if (!i18nCountryHelper.instance) {
      i18nCountryHelper.instance = new i18nCountryHelper();
    }

    return i18nCountryHelper.instance;
  }

  /**
   * Obtiene el nombre del país a partir de su código.
   * Si el código no se encuentra, se devuelve el código.
   * @param countryCode Código del país.
   */
  public getCountryName(countryCode: string): string {
    const language = LanguagesHelper.getInstance().getLanguage();
    return countries.getName(countryCode, language) || countryCode;
  }
}
