import { RouterLinkInterface } from "@/ui/interfaces/router";
import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class BreadCrumb extends Vue {
  @Prop({ default: [] }) public items!: Array<{
    text: string;
    to?: RouterLinkInterface;
  }>;
}
