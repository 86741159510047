import { AxiosHelper } from "../helpers/AxiosHelper";
import { SubscriptionNoWifiInterface } from "./SubscriptionNoWifiInterface";

export default class SubscriptionWifiService {
  protected axiosService: AxiosHelper;
  protected urlBase: string;

  constructor() {
    this.axiosService = AxiosHelper.getInstance();
    this.urlBase = process.env.VUE_APP_PRE_URI + "/subscriptionnowifi/api/v1";
  }

  /**
   * Activar periodo de prueba.
   * Debe estar en plan gratuito o dará error.
   * @param userId Id del usuario.
   * @param loginalId Id de ???.
   */
  public async activateTrial(
    userId: string,
    loginalId: string
  ): Promise<SubscriptionNoWifiInterface> {
    const url = this.urlBase + "/user/" + userId + "/trial/activate";
    return this.axiosService.put(url, { logicalId: loginalId });
  }

  /**
   * Extender el periodo de pruerba.
   * Debe estar en plan trial, o con un trial expirado sino dará error.
   * @param logicalId Id de ???.
   * @param expiredDate Fecha de expiración en formato ISO extendido (YYYYYY-MM-DD).
   */
  public async extendTrial(
    logicalId: string,
    expiredDate: string
  ): Promise<SubscriptionNoWifiInterface> {
    const url = this.urlBase + "/unit/" + logicalId + "/extend-trial";
    return this.axiosService.put(url, { expiredDate: expiredDate });
  }
}
