import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import FrmxDialog from "@/ui/components/FrmxDialog/FrmxDialog.vue";
import DateHelper from "@/ui/helpers/DateHelper";
import InstallationPenetrationEntity from "@/domain/installation/InstallationPenetrationEntity";
import InstallationController from "@/domain/controllers/InstallationController";
import { displayErrorMessage } from "@/api";

@Component({
  components: { FrmxDialog },
})
export default class InstallationPenetrationDialog extends Vue {
  @Prop({ default: false }) public value!: boolean;
  @Prop() public installationId!: string;
  public penetration: InstallationPenetrationEntity | null = null;
  public loading = true;

  public visibleDialog = false;
  dateHelper = new DateHelper();

  @Watch("value")
  private onValueChange(value: boolean) {
    if (value) {
      this.loadData();
    }
    this.visibleDialog = value;
  }

  @Watch("visibleDialog")
  private onVisibleDialogChange(value: boolean) {
    this.$emit("input", value);
  }

  private async loadData() {
    this.loading = true;
    const ctrl = new InstallationController();
    ctrl
      .getPenetration(this.installationId)
      .then((data) => {
        this.penetration = data;
      })
      .catch((err) => {
        this.onVisibleDialogChange(false);
        displayErrorMessage(err, {
          general: ["installationDetails.error.getError"],
        });
      })
      .finally(() => {
        this.loading = false;
      });
  }

  onClickClose() {
    this.onVisibleDialogChange(false);
  }
}
