import Vue from "vue";
import Component from "vue-class-component";

import { Prop } from "vue-property-decorator";
import DxfDataGrid from "../../../../components/DxfDataGrid/DxfDataGrid.vue";
import { translateText } from "@/lang/i18n";

import { Column } from "devextreme/ui/data_grid";
import PairingController from "@/domain/pairing/PairingController";
import PairingInstallationEntity from "@/domain/pairing/PairingInstallationEntity";
import SubscriptionWifiController from "@/domain/subscriptionWifi/SubscriptionWifiController";
import DeviceCacheReaderController from "@/domain/deviceCacheReader/DeviceCacheReaderController";

@Component({
  components: {
    DxfDataGrid,
  },
})
export default class InstallationDetailsUsersTable extends Vue {
  @Prop() installationId!: string;
  public title = translateText("installationDetailsUsers.titleTable");
  public data: PairingInstallationEntity[] = [];
  public columns: Column[] = [];
  public loading = true;

  mounted() {
    this.generateColumns();
    this.loadData();
  }

  private loadData() {
    this.loading = true;
    const ctrl = new PairingController();
    ctrl.getPairingByInstallationId(this.installationId).then(async (data) => {
      const ctrlSubsWifi = new SubscriptionWifiController();
      for (const pairing of data) {
        const ctrlDeviceCacheReader = new DeviceCacheReaderController();
        await ctrlDeviceCacheReader
          .getDeviceById(pairing.deviceId)
          .then((data) => {
            pairing.setDeviceCacheReader(data);
          });
        if (pairing.isWifi()) {
          await ctrlSubsWifi
            .getSubscriptionByDeviceIdUserId(pairing.deviceId, pairing.userId)
            .then((data) => {
              pairing.setSubscriptionWifi(data);
            })
            .catch(() => {
              null;
            });
        }
      }

      this.data = data;
      this.loading = false;
    });
  }

  private generateColumns() {
    const allowedOperations = ["contains", "="];
    this.columns.push({
      dataField: "userEmail",
      caption: this.$t("installationDetailsUsers.email").toString(),
      filterOperations: allowedOperations,
    });
    this.columns.push({
      dataField: "subscriptionPlanName",
      caption: this.$t(
        "installationDetailsUsers.subscriptionPlanName"
      ).toString(),
      filterOperations: allowedOperations,
    });
    this.columns.push({
      dataField: "deviceNameForTable",
      caption: this.$t("installationDetailsUsers.device").toString(),
      filterOperations: allowedOperations,
    });
    this.columns.push({
      dataField: "rol",
      caption: this.$t("installationDetailsUsers.rol").toString(),
      filterOperations: allowedOperations,
      calculateDisplayValue: (pairing: PairingInstallationEntity) => {
        return this.$t(pairing.getRolCodeT());
      },
    });
    this.columns.push({
      caption: this.$t("installationDetailsUsers.expirationDate").toString(),
      calculateDisplayValue: (pairing: PairingInstallationEntity) => {
        if (pairing.isPlanFree()) {
          const date = pairing.getFreeRenewedDateFormat();
          return this.$t("userSubscriptionDetails.renewalOn", [date]);
        }
        if (pairing.isPlanTrial()) {
          const date = pairing.getExpiredDateFormat();
          return this.$t("userSubscriptionDetails.expirationOn", [date]);
        }
        if (pairing.isPlanAnnual()) {
          const date = pairing.getExpiredDateFormat();
          return this.$t("userSubscriptionDetails.endsOn", [date]);
        }
        if (pairing.isPlanMonthly()) {
          const date = pairing.getExpiredDateFormat();
          return this.$t("userSubscriptionDetails.renewalOn", [date]);
        }
        if (pairing.isPlanUnlimited()) {
          return this.$t("userSubscriptionDetails.unlimited");
        }
        return "-";
      },
    });
    this.columns.push({
      dataField: "numUnit",
      caption: this.$t(
        "installationDetailsUsers.duoxHousingAddress"
      ).toString(),
      filterOperations: allowedOperations,
      alignment: "left",
      calculateDisplayValue: (data: PairingInstallationEntity) => {
        return data.numUnit ? data.numUnit : "-";
      },
    });
    this.columns.push({
      dataField: "address",
      caption: this.$t(
        "installationDetailsUsers.addressReportedApp"
      ).toString(),
      filterOperations: allowedOperations,
    });
  }
}
