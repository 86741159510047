<template lang="html" src="./MapFlet.html"></template>

<!-- eslint-disable @typescript-eslint/ban-types -->
<!-- eslint-disable @typescript-eslint/no-explicit-any -->
<!-- eslint-disable @typescript-eslint/no-non-null-assertion -->
<script lang="ts">
import { Vue, Component, Prop, PropSync } from "vue-property-decorator";
import * as L from "leaflet";
import "leaflet.markercluster";
import "leaflet.fullscreen";

import { LAT_FERMAX, LONG_FERMAX } from "@/mixins/marker.mixin";
import LegendCount from "@/components/TableMarkersMap/MarkersMap/LegendCount/LegendCount.vue";
import LoadingCount from "./LoadingCount/LoadingCount.vue";

@Component({
  components: {
    LegendCount,
    LoadingCount,
  },
  //mixins: [markerMixin],
})
export default class MapFlet extends Vue {
  @Prop(String) markerName!: string;
  //   @Prop({type: Object}) marker!: any;
  @Prop({ type: Function }) getIcon!: Function;
  @Prop({ type: Function }) getWithoutPagination!: Function;
  @Prop(Boolean) hasAnyActiveFilter!: boolean;
  @PropSync("isFullscreen", { type: Boolean }) localIsFullscreen!: boolean;

  $refs!: {
    //myMap: LMap,
  };
  fullScreen = false;
  currentZoom = 1.7;
  currentCenter = L.latLng(LAT_FERMAX, LONG_FERMAX);
  loadingData = false;
  map!: L.Map;
  markers: any = [];
  //markerIcon!: Function;
  exitCancel = false;
  $moment!: any;
  onLineCount = 0;
  offLineCount = 0;
  totalCount = 0;
  parcialCount = 0;
  //2000 es lo maximo que devuelve el back
  totalSize = 2000;
  sinLatitud = 0;
  conLatitud = 0;
  isLoading = false;

  get mapContainer() {
    return this.fullScreen ? "map-container-full-screen" : "map-container";
  }

  async mounted() {
    await this.loadForMarkers();
  }

  async loadForMarkers() {
    const tiles = L.tileLayer(
      //"https://tile.openstreetmap.org/{z}/{x}/{y}.png",{
      `https://api.maptiler.com/maps/outdoor/{z}/{x}/{y}.png?key=WH1IT6OmxMlw6AJ8hPso`,
      {
        //maxZoom: 3,
        //attribution:'&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Points &copy 2012 LINZ',
        attribution:
          '<a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>',
        minZoom: 1.9, // Zoom mínimo admitido, se limita para evitar zonas grises arriba y abajo al alejarse
      }
    );

    this.map ? this.map.remove() : "";

    this.map = L.map(this.markerName, {
      fullscreenControl: true,
      center: this.currentCenter,
      zoom: 3,
      layers: [tiles],
    });

    this.isLoading = false;
    this.markers = L.markerClusterGroup();
    this.map.addLayer(this.markers);
    let idx = 1;
    this.onLineCount = 0;
    this.offLineCount = 0;
    this.totalCount = 0;
    this.parcialCount = 0;

    const resp = await this.getWithoutPagination(idx, this.totalSize);
    if (!resp || resp.error) {
      return;
    }
    this.totalCount = resp.data.totalElements;
    this.isLoading = resp.data.last;
    idx = idx + 1;
    this.exitCancel = false;
    this.loadMakers(resp.data.content);
    if (resp.data!.totalPages !== 0) {
      for (
        let indexPage = idx;
        indexPage <= resp.data!.totalPages && !this.exitCancel;
        indexPage++
      ) {
        const respi = await this.getWithoutPagination(
          indexPage,
          this.totalSize
        );
        if (!respi || respi.error) {
          return;
        }
        this.loadMakers(respi.data.content);
        this.isLoading = respi.data.last;
      }
    }
  }

  markerIcon(path = "marker-icon_grey.png") {
    const url = process.env.VUE_APP_PUBLIC_PATH + "assets/markers/" + path;

    return L.icon({ iconUrl: url, iconAnchor: [15, 30] });
  }

  beforeDestroy() {
    this.exitCancel = true;
  }

  loadMakers(content) {
    content.forEach((element) => {
      if (element.l) {
        const marker = L.marker(new L.LatLng(element.l, element.n), {
          title: element.s,
          icon: this.markerIcon(this.getIcon(element)),
        });
        let content = "";
        content += '<table class="popup-table">';
        // Cabecera
        content += "<thead><tr>";
        content += '<th colspan="2" scope="col"><v-layout align-center>';
        content +=
          '<a  href="device-' + element.s + '">Device ' + element.s + "</a>";
        content += "</v-layout><v-spacer /></th></tr></thead>";
        // Cuerpo de la tabla
        content += "<tbody>";
        // Fecha de fabricación
        content += "<tr><td>";
        content += this.$t("device.productionDate");
        content += "</td><td> ";
        content += this.$moment.unix(element.md).format("DD/MM/YYYY");
        content += "</td></tr>";
        // Familia
        content += "<tr><td>";
        content += this.$t("device.family");
        content += "</td><td> ";
        content += element.f;
        content += "</td></tr>";
        // Tipo
        content += "<tr><td>";
        content += this.$t("device.type");
        content += "</td><td> ";
        content += element.t;
        content += "</td></tr>";
        // Versión de firmware
        content += "<tr><td>";
        content += this.$t("device.firmwareVersion");
        content += "</td><td>";
        content += element.version;
        content += "</td></tr>";
        // Build
        content += "<tr><td>";
        content += this.$t("device.build");
        content += "</td><td> ";
        content += element.b;
        content += "</td></tr>";
        // Orden de fabricación
        content += "<tr><td>";
        content += this.$t("device.productionOrder");
        content += "</td><td> ";
        content += element.ol;
        content += "</td></tr>";
        // Instalación
        if (element.i) {
          // Si existe, se muestra.
          content += "<tr><td>";
          content += this.$t("deviceDetails.installation");
          content += "</td><td> ";
          content += element.i;
          content += "</td></tr>";
        }
        // Señal inalámbrica
        // Si (es placa o monitor WIFI) y está online, se muestra.
        if (
          (element.f === "PANEL" ||
            (element.f === "MONITOR" && element.st === "WIFI")) &&
          element.sts
        ) {
          content += "<tr><td>";
          content += this.$t("device.properties.signal_wifi");
          content += "</td><td> ";
          content += element.wrs;
          content += "</td></tr>";
        }
        // Número de vivienda
        // Si es monitor o teléfono, se muestra.
        if (element.f === "MONITOR" || element.f === "PHONE") {
          content += "<tr><td>";
          content += this.$t("device.houseNumber");
          content += "</td><td> ";
          content += element.hnum;
          content += "</td></tr>";
        }

        // Zona
        /*content += "<tr><td>";
        content += this.$t("geo.zone");
        content += "</td><td> ";
        content += element.z;
        content += "</td></tr>";*/
        // Subzona
        /*content += "<tr><td>";
        content += this.$t("geo.subzone");
        content += "</td><td>";
        content += element.sz;
        content += "</td></tr>";*/
        // Area
        /*content += "<tr><td>";
        content += this.$t("geo.area");
        content += "</td><td> ";
        content += element.a;
        content += "</td></tr>";*/

        content += "</tbody>";
        // Cerrar tabla.
        content += "</table>";

        marker.bindPopup(content);

        /*marker.bindPopup(`<table class="popup-table">
                            <thead>
                              <tr>
                                <th colspan="2" scope="col">
                                  <v-layout align-center>
                                    <a  href="device-${element.s}">Device ${
          element.s
        }</a>
                                  </v-layout>
                                  <v-spacer />
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr><td>${this.$t(
                                "device.productionDate"
                              )}</td><td> ${this.$moment
          .unix(element.md)
          .format("DD/MM/YYYY")}</td></tr>
                              <tr><td>${this.$t("device.family")}</td><td> ${
          element.f
        }</td></tr>
                              <tr><td>${this.$t("device.type")}</td><td> ${
          element.t
        }</td></tr>
                              <tr><td>${this.$t(
                                "device.firmwareVersion"
                              )}</td><td> ${element.version}</td></rt>
                              <tr><td>${this.$t("device.build")}</td><td> ${
          element.b
        }</td></tr>
                              <tr><td>${this.$t("geo.zone")}</td><td> ${
          element.z
        }</td></tr>
                              <tr><td>${this.$t("geo.subzone")}</td><td>${
          element.sz
        }</td></tr>
                              <tr><td>${this.$t("geo.area")}</td><td> ${
          element.a
        }</td></tr>
                              </tbody>
                          </table>`);*/

        element.sts ? this.onLineCount++ : this.offLineCount++;
        this.parcialCount++;

        this.markers.addLayer(marker);
        this.conLatitud++;
      } else {
        this.sinLatitud++;
      }
    });
  }
}
</script>

<style>
.map-control-flet {
  background: #fff;
  border: 1px solid #aaa;
  border-radius: 0.1em;
  /* float: right;
  position: relative;
  z-index: 999;
  bottom: -51vh; */
  margin-right: 10px;
  margin-bottom: 20px;
}

.map-control-count {
  background: #fff;
  border: 1px solid #aaa;
  border-radius: 0.1em;
  /* float: right;
  position: relative;
  bottom: -51vh;
  z-index: 999; */
  margin-right: 75px;
  margin-bottom: 20px;
}

.map-control-full {
  background: #fff;
  border: 1px solid #aaa;
  border-radius: 0.1em;
  float: right;
  position: relative;
  z-index: 999;
  bottom: -1vh;
  margin-right: 10px;
  margin-bottom: 10px;
}

.block {
  opacity: 0.99 !important;
  display: inline-block;
  width: 100%;
  height: 60vh;
}

/* Estilo para mostrar el mapa a pantalla completa */
.full-screen {
  z-index: 10;
  width: 100%;
  height: 100vh !important;
  position: fixed !important;
  top: 0;
  left: 0;
}
.table-markers-map .v-btn .v-btn__content .v-icon {
  color: rgb(0, 0, 0, 0.54);
}

.full-screen .map-container-full-screen {
  height: 100vh !important;
}

.fullscreen-icon {
  background-image: url(icon-fullscreen.svg);
  background-size: 26px 52px;
}
.leaflet-touch .fullscreen-icon {
  background-position: 2px 2px;
}
.leaflet-touch .fullscreen-icon.leaflet-fullscreen-on {
  background-position: 2px -24px;
}
</style>
