import { exportDataGrid } from "devextreme/excel_exporter";
import { Workbook } from "exceljs";
import saveAs from "file-saver";
import dxDataGrid from "devextreme/ui/data_grid";
import DateHelper from "@/helpers/dateHelper";
import DxfExportHelper from "./DxfExportHelper";

export default class DxfExcelHelper extends DxfExportHelper {
  public export(component: dxDataGrid) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet(this.title);

    this.showAllColumns(component);

    exportDataGrid({
      component: component,
      worksheet: worksheet,
      topLeftCell: { row: 2, column: 1 },
      customizeCell: ({ gridCell, excelCell }) => {
        if (gridCell && gridCell.column) {
          if (gridCell.rowType === "header") {
            excelCell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "2F75B5" },
            };
            excelCell.font = { color: { argb: "FFFFFF" } };
          } else if (gridCell.rowType === "data") {
            const cellTemplate = gridCell.column.cellTemplate;

            if (cellTemplate === "dateFormat") {
              // Formato de fecha.
              excelCell.value = DateHelper.dateFormat(
                gridCell?.value,
                "DD/MM/YYYY HH:mm"
              );
            } else if (cellTemplate === "dateFormatUnix") {
              // Formato de fecha Unix.
              excelCell.value = DateHelper.dateFormatUnix(
                gridCell?.value,
                "DD/MM/YYYY HH:mm"
              );
            } else if (cellTemplate === "statusInstallation") {
              // Estado de la instalación.
              this.transformExcelCellStatusInstallation(
                gridCell.value,
                excelCell
              );
            } else if (cellTemplate === "cellStatusChip") {
              this.transformExcelCellStatusChip(gridCell.value, excelCell);
            } else if (cellTemplate === "cellStatusChipDervice") {
              this.transformExcelCellStatusChip(
                gridCell.value,
                excelCell,
                gridCell.data.deployed
              );
            } else if (cellTemplate === "cellAppChipInfoUser") {
              this.transformExcelCellAppChipInfoUser(gridCell.value, excelCell);
            } else if (cellTemplate === "chipJobs") {
              this.transformExcelCellChipJobs(gridCell.value, excelCell);
            } else if (
              cellTemplate === "enableTick" ||
              cellTemplate === "tickCheckCancel"
            ) {
              this.transformExcelCellEnableTick(gridCell.value, excelCell);
            } else if (cellTemplate === "rolTick") {
              this.transformExcelCellRolTick(gridCell.value, excelCell);
            }
          }
        }
      },
    })
      .then((cellRange) => {
        /** Última columna */
        const toColumn = cellRange.to?.column ?? 1;
        // Cabecera
        const headerRow = worksheet.getRow(1);
        headerRow.height = 28;
        worksheet.mergeCells(1, 1, 1, toColumn);

        const headerCell = headerRow.getCell(1);
        headerCell.value = this.title;
        headerCell.font = { bold: true, size: 16 };
        headerCell.alignment = { vertical: "middle" };

        worksheet.autoFilter = {
          from: { row: 2, column: 1 },
          to: { row: 2, column: toColumn },
        };
      })
      .then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(
            new Blob([buffer], { type: "application/octet-stream" }),
            this.getFileName() + ".xlsx"
          );
        });

        this.restoreVisibilityColumns(component);
      });
  }
}
