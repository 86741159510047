<template lang="html" src="./DeviceDetails.html"></template>
<style src="./DeviceDetails.scss" lang="scss"></style>

<!-- eslint-disable @typescript-eslint/no-explicit-any -->
<!-- eslint-disable @typescript-eslint/ban-types -->
<!-- eslint-disable @typescript-eslint/no-unused-vars -->
<script lang="ts">
import { Vue, Component, Watch, Ref } from "vue-property-decorator";
import AppBreadcrumbs from "@/components/AppBreadcrumbs.vue";
import FirmwareVersionSelect from "@/views/Firmware/FirmwareVersionSelect/FirmwareVersionSelect.vue";

import { goTo } from "@/router";

import { displayErrorMessage } from "@/api";
import deviceService from "@/api/device";
import installationService from "@/api/installation";
import rexistroService from "@/api/rexistro";

import StatusChip from "@/components/StatusChip/StatusChip.vue";

import { getMarkerIcon } from "../DeviceTabItem/DeviceTabItem.vue";
import MarkerMap from "@/components/MarkerMap/MarkerMap.vue";
import FullScreenMap from "@/components/FullScreenMap/FullScreenMap.vue";
import AppCopyButton from "@/components/AppCopyButton.vue";
import AppListMenu from "@/components/AppListMenu.vue";
import AppEventList from "@/components/AppEventList.vue";
import AppInputDate from "@/components/AppInputDate.vue";

import InstallationInformation from "./InstallationInformation/InstallationInformation.vue";
import DeviceProperties from "../DeviceProperties/DeviceProperties.vue";
import JobCreateModal from "../../Jobs/JobCreateModal/JobCreateModal.vue";
import ChangeTimezoneModal from "./ChangeTimezoneModal/ChangeTimezoneModal.vue";

import PairingStatus from "./PairingStatus/PairingStatus.vue";

import dateMixin from "@/mixins/date.mixin";
import markerMixin from "@/mixins/marker.mixin";
import stringMixin from "@/mixins/string.mixin";
import planMixin from "@/mixins/plan.mixin";

import fermaxSubscriptionApi from "@/api/fermaxSubscription";
import detailsMixin from "@/mixins/details.mixin";
import i18n from "@/lang/i18n";
import { SocketioService } from "@/infra/socket-client";

//const ioService = new SocketioService();
let ioService = Array<SocketioService>(); // new SocketioService();

import QRCode from "@/components/QRCode.vue";

import MediaService from "@/infra/media-sevice";
const { join_call, pickup, hang_up } = MediaService();
import Ustring from "@/mixins/string";

import moment from "moment";

const QR_CODE_PATH =
  "https://www.opendit.com/es/connect?utm_source=fermax-phone&utm_medium=sticker&uuid=";

import TooltipAndCopy from "@/ui/components/TooltipAndCopy/TooltipAndCopy.vue";
import TooltipAndDateTime from "@/ui/components/TooltipAndDateTime/TooltipAndDateTime.vue";

import UserTokenEntity from "@/domain/entities/UserTokenEntity";
import DeviceEntity from "@/domain/entities/DeviceEntity";

import CallDivertCard from "@/views/Devices/DeviceDetails/CallDivertCard/CallDivertCard.vue";

import Toast from "@/ui/helpers/Toast";

import PairedUserTable from "./PairedUserTable/PairedUserTable.vue";
import AddressDialog from "./AddressDialog/AddressDialog.vue";

import { DeviceAddressInterface } from "@/domain/interfaces/DeviceInterface";
import PairingUserEntity from "@/domain/pairing/PairingUserEntity";
import LogSupport from "./LogSupport/LogSupport.vue";

interface installationDetailsInterface {
  city: string;
  country: string;
  postcode: string;
  province: string;
}

@Component({
  components: {
    AppBreadcrumbs,
    MarkerMap,
    FullScreenMap,
    StatusChip,
    FirmwareVersionSelect,
    InstallationInformation,
    DeviceProperties,
    JobCreateModal,
    PairingStatus,
    ChangeTimezoneModal,
    AppCopyButton,
    AppListMenu,
    AppEventList,
    AppInputDate,
    QRCode,
    TooltipAndCopy,
    TooltipAndDateTime,
    CallDivertCard,
    PairedUserTable,
    AddressDialog,
    LogSupport,
  },

  mixins: [dateMixin, markerMixin, stringMixin, detailsMixin, planMixin],
  data() {
    return {
      deviceId: this.$route.params.deviceId,
    };
  },
})
export default class DeviceDetails extends Vue {
  @Ref("remoteVideo") remoteVideo!: any;

  userTokenEntity: UserTokenEntity = new UserTokenEntity(
    this.$store.getters["accessToken"] ?? ""
  );

  //$moment!: any;
  //has_content!: Function;
  //all_has_content!: Function;
  checkItemNotFound!: Function;
  hasJob!: boolean;
  confirmSimTestExecute!: any;

  deviceId!: string;
  selected = [];
  // Dialog to create a job
  updateVersionDialog = false;
  // Map in fullscreen mode
  fullScreenMarkerMap = false;
  // Image for the monitor
  imgURL = "";
  // Array of pairings with user info
  userPairedWithDevice = null;
  imgHasError = false;
  isRefreshing = false;
  questionSrc =
    process.env.VUE_APP_PUBLIC_PATH + "assets/devices/" + "unknown.jpg";
  showDeviceProperties = false;
  showChangeTimezone = false;
  showConfirmSimTestExecute = false;
  confirmActivateSim = false;
  isActivatedSim = false;
  isExecutingSimTest = false;
  showConfirmSimChangeState = false;
  isChangingSimState = false;
  showConfirmRefreshInstallation = false;
  showConfirmDeleteIoT = false;
  showConfirmDeleteInstallation = false;
  showNotifyRefresh = false;
  isRefreshingInstallation = false;
  editDialog = false;
  tab = null;
  deviceDetailsTableProps = {
    cols: 12,
    sm: 6,
    md: 4,
    lg: 3,
  };
  showConfirmResetTrial = false;
  isResettingTrial = false;
  tabs = ["tab-1", "tab-2", "tab-3"];
  active = null;

  $refs!: any;
  deviceDetail!: any;

  showEventHistory = false;
  showEventAutoOn = false;
  isLoadingEvents = false;
  eventsHistory = Array<{
    time: number;
    deviceId: string;
    type: string;
    subtype: string;
  }>();
  totalPagesEvent = 0;
  totalSizeEvent = 0;
  pageNumberEvent = 1;
  endDateHistory = moment().format("YYYY-MM-DD");
  startDateHistory = moment().subtract(30, "days").format("YYYY-MM-DD");
  autoonFields = {
    roomID: "",
    pickup: false,
    joinCall: false,
    socketid: null,
  };
  selectedDevices: Array<any> = [];
  showQrCode = false;
  url = "https://www.fermax.com/blue?type=NO_WIFI&uuid=";

  get deviceCacheDetails() {
    return this.$store.getters["devices/oneDeviceCacheData"];
  }

  get devicesDetails() {
    return this.$store.getters["devices/oneDeviceData"];
  }

  get image() {
    return this.imgHasError ? this.questionSrc : this.imgURL;
  }

  get deviceFwDetails() {
    return this.$store.getters["firmwares/oneFirmwareInfoByType"];
  }

  /*get oneDevicePairings() {
    return this.$store.getters["devices/oneDevicePairings"];
  }*/

  get installationDetails() {
    return this.$store.getters["installations/installationDetails"];
  }

  get userData() {
    return this.$store.getters["users/oneUserData"];
  }

  get deviceWithWarningJob() {
    return this.$store.getters["jobs/devicesWithJob"];
  }

  get currentJob() {
    return this.$store.getters["jobs/jobInvolvedOnMyDevice"];
  }

  get hasCurrentJob() {
    return this.deviceWithWarningJob[0] === this.deviceId;
  }

  get lastJob() {
    return this.$store.getters["jobs/lastJobFromDevice"];
  }
  get hasLastJob() {
    return this.lastJob !== null;
  }

  get deviceSimInfo() {
    return this.$store.getters["devices/oneDeviceSim"];
  }

  get menuOptions() {
    return [
      {
        icon: "system_update",
        text: this.$t("updatefw.firmware"),
        action: () => this.setShowUpdateVersionDialog(true),
        allowed:
          (this.deviceCacheDetails.status === true ||
            (this.deviceCacheDetails.family === "MONITOR" &&
              this.deviceCacheDetails.connectable == false &&
              this.deviceCacheDetails.deployed == true)) &&
          this.hasCurrentJob === false &&
          this.deviceCacheDetails.family !== "PHONE" &&
          //this.deviceCacheDetails.family !== "GUARDUNIT" &&
          this.$ability.can("update", "devices"),
      },
      {
        icon: "work",
        text: this.$t("deviceDetails.showJob"),
        to: () => ({
          name: "JobDetails",
          params: { jobid: this.currentJob },
        }),
        allowed: this.hasCurrentJob && this.$ability.can("details", "jobs"),
      },
      {
        icon: "work",
        text: this.$t("deviceDetails.showLastJob"),
        action: () => this.goToLastJob(),
        allowed:
          this.hasLastJob &&
          !this.hasCurrentJob &&
          this.$ability.can("details", "jobs"),
      },
      {
        icon: "fa-database",
        text: this.$t("deviceDetails.obtainBBDD"),
        action: () => this.obtainBBDDinfo(),
        allowed:
          (this.deviceCacheDetails.family === "PANEL" ||
            this.deviceCacheDetails.family === "EDIBOX") &&
          !!this.installationDetails &&
          !this.userTokenEntity.isAfterSales,
      },
      {
        icon: "fa-file-invoice-dollar",
        text: this.$t("deviceDetails.pullSubscription"),
        action: () => this.pullSubscriptions(),
        allowed:
          (this.deviceCacheDetails.family === "PANEL" ||
            this.deviceCacheDetails.family === "EDIBOX") &&
          this.deviceCacheDetails.status === true &&
          this.$ability.can("refresh", "devices") &&
          !this.userTokenEntity.isAfterSales,
      },
      {
        icon: "dns",
        text: this.$t("deviceDetails.showProperties"),
        action: () => this.setShowDeviceProperties(true),
        allowed: this.$ability.can("property", "devices"),
      },
      {
        icon: "access_time",
        text: this.$t("deviceDetails.changeTimezone.title"),
        action: () => this.setShowChangeTimezone(true),
        allowed:
          (this.deviceCacheDetails.family === "PANEL" ||
            this.deviceCacheDetails.family === "EDIBOX") &&
          this.deviceCacheDetails.status === true &&
          this.$ability.can("timezone", "devices"),
      },
      {
        icon: "add_task",
        text: this.$t("deviceDetails.activateSim.title"),
        action: () => this.setConfirmActivateSim(true),
        allowed:
          (this.deviceCacheDetails.family === "PANEL" ||
            this.deviceCacheDetails.family === "EDIBOX") &&
          this.deviceCacheDetails.iccid &&
          this.statusInactive &&
          this.$ability.can("activateSIM", "devices"),
      },
      {
        icon: "sim_card",
        text: this.$t("deviceDetails.simTestExecute.title"),
        action: () => this.setConfirmSimTestExecute(true),
        allowed:
          (this.deviceCacheDetails.family === "PANEL" ||
            this.deviceCacheDetails.family === "EDIBOX") &&
          ((this.deviceCacheDetails.iccid && this.statusActive) ||
            (this.deviceCacheDetails.iccid && this.statusTest)) &&
          this.$ability.can("testSIM", "devices"),
      },
      {
        icon: "no_sim",
        text: this.$t("deviceDetails.changeSimState.titleDisable"),
        action: () => this.setShowConfirmSimChangeState(true),
        allowed:
          (this.deviceCacheDetails.family === "PANEL" ||
            this.deviceCacheDetails.family === "EDIBOX") &&
          this.deviceCacheDetails.iccid &&
          this.statusActive &&
          this.$ability.can("disableSIM", "devices"),
      },
      {
        icon: "settings_backup_restore",
        text: this.$t("deviceDetails.trial.reset"),
        action: () => this.setShowConfirmResetTrial(true),
        allowed:
          (this.deviceCacheDetails.family === "MONITOR" ||
            this.deviceCacheDetails.family === "PHONE") &&
          !this.deviceCacheDetails.connectable &&
          this.trialIsNotAvailable &&
          this.$ability.can("resetTrial", "devices"),
      },
      {
        icon: "device_hub",
        text: this.$t("deviceDetails.goToInstallation"),
        action: () => this.goToInstallation(),
        allowed:
          !!this.deviceCacheDetails.installationId &&
          this.$ability.can("details", "installations"),
      },
      {
        icon: "refresh",
        text: this.$t("deviceDetails.refreshView"),
        action: () => this.refreshDevice(),
        allowed: this.$ability.can("refresh", "devices"),
      },
      {
        icon: "my_location",
        text: this.$t("deviceDetails.refreshLocation"),
        action: () => this.refreshLocation(),
        allowed:
          (this.deviceCacheDetails.family === "PANEL" ||
            this.deviceCacheDetails.family === "EDIBOX") &&
          this.deviceCacheDetails.iccid &&
          this.statusActive &&
          this.$ability.can("refreshLocation", "devices"),
      },
      {
        icon: "sync",
        text: this.$t("installationDetails.refreshPanels"),
        action: () => this.setShowConfirmRefreshInstallation(true),
        allowed:
          this.deviceCacheDetails.family === "PANEL" &&
          this.deviceCacheDetails.status === true &&
          this.$ability.can("refresh", "devices"),
      },
      {
        icon: "delete_forever",
        text: this.$t("deviceDetails.deleteIot"),
        action: () => this.setShowConfirmDeleteIoT(true),
        allowed:
          this.deviceCacheDetails.family === "MONITOR" &&
          this.deviceCacheDetails.deployed != false &&
          this.$ability.can("delete", "devices"),
      },
      {
        icon: "delete_forever",
        text: this.$t("deviceDetails.deleteInstallation"),
        action: () => this.setShowConfirmDeleteInstallation(true),
        allowed:
          this.deviceCacheDetails.installationId != "" &&
          this.$ability.can("delete", "devices"),
      },
      {
        icon: "fa-clipboard-list",
        iconProps: { size: 20, style: { "margin-left": "2px" } },
        text: this.$t("general.eventHistory"),
        action: () => this.openEventHistory(),
        allowed: this.$ability.can("eventHistory", "installation"),
      },
      {
        icon: "sync",
        text: this.$t("installationDetails.restartDevice"),
        action: () => this.restartDevice(),
        allowed:
          this.deviceCacheDetails.family === "EDIBOX" &&
          this.$ability.can("refresh", "devices"),
      },
      {
        icon: "fa-video",
        text: this.$t("deviceDetails.goToVideo"),
        action: () => this.autoon(),
        allowed: this.canDoAutoon && this.$ability.can("autoon", "devices"),
      },
      {
        icon: "fa-qrcode",
        text: this.$t("deviceDetails.generateQR"),
        action: () => this.qrCode(),
        allowed: this.canGenerateQrCode(),
      },
      {
        icon: "open_in_new",
        text: this.$t("deviceDetails.grafana"),
        action: () => {
          const urlGrafana = this.$store.getters["getUriGrafanaDevice"];

          if (!urlGrafana) {
            Toast.error("deviceDetails.grafanaNotUrl");
            return;
          }
          const serialNumber = this.deviceCacheDetails.serialNumber;
          const url = urlGrafana.replace("{serialNumber}", serialNumber);
          window.open(url, "_blank");
        },
        allowed: this.canGrafana(),
      },
      {
        icon: "real_estate_agent",
        text: this.$t("deviceDetails.pairedUsers.guestMasterDialog.addMaster"),
        action: () => {
          this.showAddMaster();
        },
        allowed: this.canShowAddMaster(),
      },
      {
        icon: "location_on",
        text: this.$t("deviceDetailsAddress.address"),
        action: () => {
          this.visibleAddressDialog = true;
        },
        allowed: this.canShowAddressDialog(),
      },
    ];
  }
  canGenerateQrCode(): boolean {
    const deviceEntity = new DeviceEntity();
    deviceEntity.family = this.deviceCacheDetails.family;
    deviceEntity.type = this.deviceCacheDetails.type;
    return (
      deviceEntity.canGenerateQrCode() && this.$ability.can("qrCode", "devices")
    );
  }

  canGrafana(): boolean {
    const deviceEntity = new DeviceEntity();
    deviceEntity.family = this.deviceCacheDetails.family;
    deviceEntity.subtype = this.deviceCacheDetails.subtype;
    return deviceEntity.canGrafana();
  }

  public onChangeMaster(master: PairingUserEntity | undefined) {
    this.address.address = master?.address || "";
  }

  public address: DeviceAddressInterface = {
    address: "",
    zipCode: "",
    location: "",
    country: "",
    province: "",
  };

  /** Devuelve si existe dirección */
  private existsAddress(): boolean {
    return (
      this.address.address !== "" ||
      this.address.zipCode !== "" ||
      this.address.location !== "" ||
      this.address.country !== "" ||
      this.address.province !== ""
    );
  }

  public visibleAddressDialog = false;

  private canShowAddressDialog() {
    const deviceEntity = new DeviceEntity();
    deviceEntity.family = this.deviceCacheDetails.family;
    return this.existsAddress() && deviceEntity.canShowAddress();
  }

  get trialIsNotAvailable() {
    return (
      !this.deviceCacheDetails.trialAvailable &&
      (!this.deviceCacheDetails.trialEndDate ||
        moment(this.deviceCacheDetails.trialEndDate).isBefore(moment()))
    );
  }

  get viewPaired() {
    if (this.deviceCacheDetails === null) {
      return false;
    }
    const deviceEntity: DeviceEntity = new DeviceEntity(
      this.deviceCacheDetails
    );
    return deviceEntity.showTablePaired();
  }

  showCallDivertTable() {
    if (this.deviceCacheDetails === null) {
      return false;
    }
    const deviceEntity: DeviceEntity = new DeviceEntity(
      this.deviceCacheDetails
    );
    return deviceEntity.showTableCallDivert();
  }

  get changeState() {
    return (
      (this.deviceCacheDetails.family === "PANEL" ||
        this.deviceCacheDetails.family === "EDIBOX") &&
      this.deviceCacheDetails.iccid &&
      this.statusActive
    );
  }

  get statusString() {
    if (
      this.deviceSimInfo &&
      this.deviceSimInfo.status &&
      this.deviceSimInfo.status != null &&
      this.deviceSimInfo.status.length > 0 &&
      this.$te("deviceDetails.status." + this.deviceSimInfo.status)
    ) {
      return this.$t("deviceDetails.status." + this.deviceSimInfo.status);
    } else return "-";
  }

  get statusTest() {
    return this.deviceSimInfo && this.deviceSimInfo.status === "TEST";
  }
  get statusInactive() {
    return this.deviceSimInfo && this.deviceSimInfo.status === "INACTIVE_NEW";
  }

  get statusActive() {
    return this.deviceSimInfo && this.deviceSimInfo.status === "ACTIVE";
  }

  get instInformation() {
    return (
      this.installationDetails &&
      this.deviceCacheDetails &&
      this.deviceCacheDetails.installationId
    );
  }
  get deviceTrial() {
    let trialStatus = "available";

    if (!this.deviceCacheDetails.trialAvailable && this.trialIsNotAvailable)
      trialStatus = "notAvailable";

    return this.$t(`deviceDetails.trial.${trialStatus}`);
  }

  get canDoAutoon() {
    return (
      !(
        (this.deviceCacheDetails.family === "MONITOR" &&
          this.deviceCacheDetails.subtype == "NOWIFI") ||
        (this.deviceCacheDetails.family === "MONITOR" &&
          this.deviceCacheDetails.subtype == "NOWIFI-DDA") ||
        this.deviceCacheDetails.family === "PHONE" ||
        this.deviceCacheDetails.family === "EDIBOX" ||
        this.deviceCacheDetails.family === "GUARDUNIT"
      ) && this.deviceCacheDetails.status
    );
  }

  get signalWifi() {
    return (
      this.deviceCacheDetails.family === "PANEL" ||
      //this.deviceCacheDetails.family === "EDIBOX"  ||
      (this.deviceCacheDetails.family === "MONITOR" &&
        this.deviceCacheDetails.subtype == "WIFI")
    );
  }

  get isPanel() {
    return (
      this.devicesDetails &&
      this.devicesDetails.tags &&
      this.devicesDetails.tags.Family === "PANEL"
    );
  }

  @Watch("deviceCacheDetails")
  onChangeDeviceCacheDetails() {
    this.getFirmwareInfo();
    this.getInstallationDetails();
    this.setDeviceImage();
    if (
      (this.deviceCacheDetails.family == "PANEL" ||
        this.deviceCacheDetails.family == "EDIBOX") &&
      this.deviceCacheDetails.iccid
    ) {
      this.getDeviceSimInfo();
    }
  }

  @Watch("installationDetails")
  onChangeInstallationDetails(
    installationDetails: installationDetailsInterface
  ) {
    this.address.country = installationDetails.country || "";
    this.address.province = installationDetails.province || "";
    this.address.zipCode = installationDetails.postcode || "";
    this.address.location = installationDetails.city || "";
  }

  beforeCreate() {
    this.$store.commit("devices/setOneDeviceCacheData", null);
    this.$store.commit("devices/setOneDeviceData", null);
    //this.$store.commit("devices/setOneDevicePairings", []);
    this.$store.commit("devices/setOneDeviceSim", null);
    this.$store.commit("users/setOneUserData", []);
    this.$store.commit("firmwares/setOneFirmwareInfoByType", {
      changelog: "-",
      details: "-",
    });
  }

  mounted() {
    this.$nextTick(() => {
      this.getProperties();
    });
    this.getDeviceInfos();
  }

  getMarkerIcon = getMarkerIcon;

  setShowUpdateVersionDialog(v) {
    this.updateVersionDialog = v;
  }

  setShowDeviceProperties(v) {
    this.showDeviceProperties = v;
    this.refreshDevice(false);
  }

  setShowChangeTimezone(v) {
    this.showChangeTimezone = v;
  }

  setConfirmSimTestExecute(v) {
    this.showConfirmSimTestExecute = v;
  }

  setConfirmActivateSim(v) {
    this.confirmActivateSim = v;
  }

  setShowConfirmSimChangeState(v) {
    this.showConfirmSimChangeState = v;
  }

  setShowConfirmResetTrial(v) {
    this.showConfirmResetTrial = v;
  }

  setShowConfirmRefreshInstallation(v) {
    this.showConfirmRefreshInstallation = v;
  }

  setShowConfirmDeleteIoT(v) {
    this.showConfirmDeleteIoT = v;
  }

  setShowConfirmDeleteInstallation(v) {
    this.showConfirmDeleteInstallation = v;
  }

  setShowNotifyRefresh(v) {
    this.showNotifyRefresh = v;
  }

  getDeviceCacheInfo() {
    // Retrieve the device information from the device cache reader
    return this.$store
      .dispatch("devices/getOneDeviceCacheData", {
        deviceId: this.deviceId,
      })
      .catch((e) => this.checkItemNotFound(e, "devices"));
  }
  getInstallationDetails() {
    if (this.deviceCacheDetails.installationId) {
      return this.$store.dispatch("installations/getInstallationDetails", {
        id: this.deviceCacheDetails.installationId,
      });
    } else {
      this.address.country = this.deviceCacheDetails.countryiso2;
      this.address.province = this.deviceCacheDetails.province;
      this.address.location = this.deviceCacheDetails.city;
    }
  }

  getProperties() {
    this.$store.dispatch("devices/getOneDeviceData", {
      deviceId: this.deviceId,
    });
  }

  checkJobOn() {
    // To check if the device has a job running
    return this.$store.dispatch("jobs/getDevicesWithJobOn", {
      selectedMonitors: [this.deviceId],
    });
  }

  checkLastJob() {
    // To check if the device has a job running
    return this.$store.dispatch("jobs/getLastJobFromDevice", {
      deviceId: this.deviceId,
    });
  }

  canShowAddMaster() {
    const deviceEntity: DeviceEntity = new DeviceEntity(
      this.deviceCacheDetails
    );
    if (this.$refs.pairedUserTable && deviceEntity.canAddMaster()) {
      return !this.$refs.pairedUserTable.existsMaster();
    }
    return false;
  }

  showAddMaster() {
    if (this.$refs.pairedUserTable) {
      this.$refs.pairedUserTable.showAddMaster();
    }
  }

  getDeviceInfos() {
    if (this.$refs.pairedUserTable) {
      // Si hay alguna actualización en el dispositivo, se recarga la table.
      this.$refs.pairedUserTable.loadData();
    }

    return Promise.all([
      this.getDeviceCacheInfo(),
      this.checkJobOn(),
      this.checkLastJob(),
    ]);
  }

  prepareToEdit(propertiesDevices) {
    this.showDeviceProperties = true;
    this.deviceDetail = propertiesDevices;
  }

  getFirmwareInfo() {
    this.$store.dispatch("firmwares/getOneFirmwareByType", {
      device: this.deviceCacheDetails,
    });
  }

  goToInstallation() {
    goTo(`installation-${this.deviceCacheDetails.installationId}`);
  }

  goToLastJob() {
    goTo(`job-${this.lastJob}`);
  }

  setDeviceImage() {
    let deviceFile = "unknown.jpg";
    const isAllContent = Ustring.all_has_content(
      this.deviceCacheDetails.family,
      this.deviceCacheDetails.type,
      this.deviceCacheDetails.subtype
    );
    if (isAllContent && this.deviceCacheDetails.family != "GUARDUNIT") {
      deviceFile =
        this.deviceCacheDetails.family +
        "_" +
        this.deviceCacheDetails.type +
        "_" +
        this.deviceCacheDetails.subtype +
        ".jpg";
    } else if (this.deviceCacheDetails?.family) {
      deviceFile = this.deviceCacheDetails.family + ".jpg";
    }

    this.imgURL =
      process.env.VUE_APP_PUBLIC_PATH + "assets/devices/" + deviceFile;
  }

  openFullScreenMarkerMap() {
    this.fullScreenMarkerMap = true;
  }

  statusText(deploy, status) {
    if (deploy === false) {
      return this.$t("device.manufactured");
    } else {
      return status === true
        ? this.$t("device.online")
        : this.$t("device.offline");
    }
  }

  colorStatus(deploy, status) {
    if (deploy === false) {
      return "grey lighten-2 black--text";
    } else {
      return status === true
        ? "green white--text"
        : "grey darken-2 white--text";
    }
  }

  firmwareStatusText(firmwareLatest) {
    if (this.hasJob) {
      return this.$t("deviceDetails.jobRun");
    } else {
      return firmwareLatest === true
        ? this.$t("deviceDetails.fwLatest")
        : this.$t("deviceDetails.fwOutdated");
    }
  }

  firmwareStatusColor(firmwareLatest) {
    if (this.hasJob) {
      return "primary white--text";
    } else {
      return firmwareLatest === true
        ? "green white--text"
        : "grey darken-2 white--text";
    }
  }

  refreshDevice(showSuccess = true) {
    this.isRefreshing = true;
    showSuccess === false
      ? (this.isRefreshing = false)
      : (this.isRefreshing = true);
    const stringMonitorsUrl = "devices=" + this.deviceId;
    deviceService.refreshDevice(stringMonitorsUrl).then(
      () => {
        this.getDeviceInfos().then(() => {
          this.isRefreshing = false;

          if (showSuccess)
            this.$store.dispatch("snackbarInfo", {
              text: this.$t("device.info.refreshDeviceInfo"),
            });
        });
      },
      (error) =>
        displayErrorMessage(error, {
          general: ["general.error.refreshError"],
        })
    );
  }

  // Autoon

  AutoOnInitValues() {
    this.autoonFields.pickup = false;
    this.autoonFields.joinCall = false;
    this.autoonFields.roomID = "";
    ioService.forEach((service) => {
      service.disconnect();
    });
    //ioService.disconnect();
    this.autoonFields.socketid = null;
    this.socketOnClose();
  }

  OutEventAutonOn() {
    //this.AutoOnInitValues();
    this.autoOnDiconnectClick();
  }

  autoonExistsConnection() {
    return this.autoonFields.socketid;
  }

  autoon() {
    this.AutoOnInitValues();

    //const urlServer="https://calldivertsignserver-devel-blue.fermax.cloud";
    const getCallDiverts = this.$store.getters["getCallDiverts"]?.split(",");

    if (getCallDiverts == undefined) {
      displayErrorMessage("", {
        general: ["CallDivert env No defined"],
      });
      return;
    }

    this.showEventAutoOn = true;
    ioService = [];

    getCallDiverts.forEach((urlCallDivert) => {
      const ioServiceInstance = new SocketioService();
      ioServiceInstance.setupSocketConnection(
        urlCallDivert,
        (connect: boolean) => {
          if (connect) {
            ioService.push(ioServiceInstance);
            console.log("Conexion Socket OK ", ioServiceInstance.socket.id);
            this.autoonFields.socketid = ioServiceInstance.socket.id;
            this.socketOnbrowser(ioServiceInstance);
          } else {
            console.log("Error");
          }
        }
      );
    });
  }

  socketOnbrowser(ioServiceInstance) {
    ioServiceInstance.socket.on("on-browser-autoon", (data: any) => {
      this.autoonFields.joinCall = false;

      const idSocket1 = `web-${ioService[0].socket.id}`;
      const idSocket2 =
        ioService.length !== 1 ? `web-${ioService[1].socket.id}` : "";

      if (
        data &&
        (data.callingTo == idSocket1 || data.callingTo == idSocket2)
      ) {
        this.autoonFields.roomID = data.roomId || "";
        const json = {
          roomId: data.roomId,
          appToken: data.callingTo,
          fermaxOauthToken: data.token,
          protocolVersion: "0.0.9",
        };
        const srcObject = new MediaStream();
        this.remoteVideo.srcObject = srcObject;

        join_call(
          json,
          srcObject,
          this.socketOnClose,
          ioServiceInstance.socket
        ).then(
          () => {
            this.autoonFields.joinCall = true;
          },
          (error) => {
            //this.$refs.remoteVideo = null;
            console.error(error);
          }
        );
      }
    });
  }

  socketOnClose() {
    if (this.remoteVideo?.srcObject) {
      this.remoteVideo.srcObject = null;
    }
  }

  async autoOnConnectClick() {
    //const payload = { "directedToBluestream":"fI6opP9ZOkrcoA3Mn0hi9h:APA91bHjY-4sDIam_qKumY20EIZrVh8IsImBrRfJszKSuQAqcPlK61AJAZXZ32_I1r0z42k1qtT8K0RgI7qxKvHD5fKsxL4ktyeHQU4qIZRycec-uUbm6IrqN1hhVTUeGD0LKu0ECnnB"}
    if (ioService.length == 0) {
      return;
    }

    const ioServiceInstance = ioService[0];

    const payload = {
      directedToBluestream: `web-${ioServiceInstance.socket.id}`,
    };
    try {
      //await deviceService.executeAutoon("203932385243570a00200049",payload);
      const resp = await deviceService.executeAutoon(
        this.deviceCacheDetails.serialNumber,
        payload
      );
      //console.log(resp);
      if (resp && resp.status !== 200) {
        displayErrorMessage(resp.statusText);
      }
      //await deviceService.executeAutoon(this.deviceCacheDetails.serialNumber,payload);
    } catch (error) {
      this.autoonFields.joinCall = false;
      displayErrorMessage(error, {
        general: ["general.error.autoonError"],
      });
    }
  }

  autoOnDiconnectClick() {
    if (this.autoonFields.joinCall) {
      hang_up()
        .catch(function (error) {
          // Ignore "Room is not available anymore" error and just close the client
          if (error.code !== "ERR_ASSERTION") throw error;
        })
        .then(
          () => {
            //emit("end-call", props.ipanel);
            console.log("--- endcall:");
          },
          function (error: any) {
            console.error(error);
          }
        );
    }
    this.AutoOnInitValues();
    this.showEventAutoOn = false;
  }

  autoOngetAudioTrack(stream: any, index = 0) {
    return stream.getAudioTracks()[index];
  }

  autoOnPickup() {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then(this.autoOngetAudioTrack)
      .then(pickup)
      .catch(function (error) {
        console.error("Pickup", error);
      });
    this.autoonFields.pickup = true;
    console.log("--- pickUp: OK");
  }

  // Autoon

  restartDevice() {
    this.isRefreshing = true;
    deviceService.restartDevice(this.deviceId).then(
      () => {
        this.getDeviceInfos().then(() => {
          this.isRefreshing = false;
        });
      },
      (error) =>
        displayErrorMessage(error, {
          general: ["general.error.restartError"],
        })
    );
  }

  i18nDetails(stat) {
    return i18n.t("device.deviceDetails." + stat);
  }

  insertNewProperties() {
    const properties = {
      method: "changeproperty",
      payload: {
        property: this.$refs.element.id,
        value: this.devicesDetails.reported.dnd_mode,
      },
    };

    // To update the properties in edit
    deviceService
      .sendProperties(this.deviceId, properties)
      .then(() => {
        this.$store.dispatch("snackbarInfo", {
          active: true,
          text: this.$t("deviceDetails.alertProperties"),
        });
      })

      .catch((err) => {
        displayErrorMessage(err, {
          general: ["oauthclients.error.updateFail"],
        });
      });
  }

  getDeviceSimInfo() {
    this.$store.dispatch("devices/getOneDeviceSimData", {
      iccid: this.deviceCacheDetails.iccid,
    });
  }

  cancelEdit() {
    this.editDialog = false;
  }

  executeSimTest() {
    this.isExecutingSimTest = true;

    const closeTestResult = (err) => {
      this.isExecutingSimTest = false;
      this.showConfirmSimTestExecute = false;

      if (!err)
        this.$store.dispatch("snackbarInfo", {
          text: this.$t("deviceDetails.simTestExecute.results.success"),
        });
      else
        displayErrorMessage(err, {
          general: ["deviceDetails.simTestExecute.results.error"],
          notFound: ["deviceDetails.simTestExecute.results.hasNotSim"],
          badRequest: ["deviceDetails.simTestExecute.results.fail"],
        });
    };

    deviceService
      .executeDeviceSimTest(this.deviceCacheDetails.iccid)
      .then(() => closeTestResult(null))
      .catch((err) => closeTestResult(err));
  }

  activateSim() {
    this.isActivatedSim = true;

    deviceService
      .activateSim(this.deviceCacheDetails.iccid)
      .then(() => {
        this.confirmActivateSim = false;
        this.isActivatedSim = false;
        this.$store.dispatch("snackbarInfo", {
          active: true,
          text: this.$t("deviceDetails.activateSim.correct"),
        });
      })

      .catch((err) => {
        this.isActivatedSim = false;
        displayErrorMessage(err, {
          general: ["deviceDetails.activateSim.error"],
        });
      });
  }

  changeSimState() {
    this.isChangingSimState = true;
    let action = this.statusActive ? "deactivate" : "reactivate";

    const closeChangeStateResult = (err) => {
      this.isChangingSimState = false;
      this.showConfirmSimChangeState = false;

      if (!err) {
        let textStatus = this.statusActive
          ? this.$t("deviceDetails.changeSimState.disabled")
          : this.$t("deviceDetails.changeSimState.enabled");
        this.$store.dispatch("snackbarInfo", {
          text: this.$t("deviceDetails.changeSimState.results.success", [
            textStatus,
          ]),
        });
        this.getDeviceSimInfo();
      } else {
        let textStatus = this.statusActive
          ? this.$t("deviceDetails.changeSimState.disable")
          : this.$t("deviceDetails.changeSimState.enable");
        displayErrorMessage(err, {
          general: ["deviceDetails.changeSimState.results.error", [textStatus]],
          notFound: ["deviceDetails.simTestExecute.results.hasNotSim"],
        });
      }
    };

    deviceService
      .executeDeviceSimChangeState(this.deviceCacheDetails.iccid, action)
      .then(() => closeChangeStateResult(null))
      .catch((err) => closeChangeStateResult(err));
  }

  resetTrialDevice() {
    this.isResettingTrial = true;

    fermaxSubscriptionApi.resetTrialDevice(this.deviceId).then(
      () => {
        this.isResettingTrial = false;
        this.showConfirmResetTrial = false;

        this.$store.dispatch("snackbarInfo", {
          text: this.$t("deviceDetails.trial.resetSuccess"),
        });

        this.refreshDevice(false);
      },
      (err) => {
        displayErrorMessage(err, {
          general: ["deviceDetails.trial.resetError"],
        });
        this.isResettingTrial = false;
      }
    );
  }

  obtainBBDDinfo() {
    installationService.obtainBBDD(this.deviceId).catch((err) =>
      displayErrorMessage(err, {
        general: ["deviceDetails.error.obtainBBDD"],
      })
    );
  }

  refreshLocation() {
    deviceService
      .refreshLocation(this.deviceId)
      .then(() => {
        this.$store.dispatch("snackbarInfo", {
          active: true,
          text: this.$t("deviceDetails.alertProperties"),
        });
        this.getDeviceInfos();
      })

      .catch((err) => {
        displayErrorMessage(err, {
          general: ["general.error.refreshError"],
        });
      });
  }

  pullSubscriptions() {
    deviceService
      .pullSubscriptions(this.deviceId)
      .then(() => {
        this.$store.dispatch("snackbarInfo", {
          active: true,
          text: this.$t("deviceDetails.alertProperties"),
        });
        this.getDeviceInfos();
      })

      .catch((err) => {
        displayErrorMessage(err, {
          general: ["general.error.refreshError"],
        });
      });
  }

  refreshInstallation() {
    this.isRefreshingInstallation = true;
    deviceService
      .refreshInstallation(this.deviceId)
      .then(() => {
        this.$store.dispatch("snackbarInfo", {
          active: true,
          text: this.$t("installationDetails.refreshedInfo"),
        });
        this.setShowNotifyRefresh(true);
      })

      .catch((err) => {
        displayErrorMessage(err, {
          general: ["general.error.refreshError"],
        });
      })
      .finally(() => {
        this.isRefreshingInstallation = false;
        this.showConfirmRefreshInstallation = false;
      });
  }

  deleteDeviceIoT() {
    deviceService
      .deleteDeiceIoT(this.deviceId)
      .then(() => {
        this.$store.dispatch("snackbarInfo", {
          active: true,
          text: this.$t("deviceDetails.deleteDeviceIoT.completed"),
        });
      })

      .catch((err) => {
        displayErrorMessage(err, {
          general: ["general.error.refreshError"],
        });
      })
      .finally(() => {
        this.refreshDevice(false);
        this.showConfirmDeleteIoT = false;
      });
  }

  deleteDeviceInstallation() {
    this.selectedDevices.push(this.deviceCacheDetails.serialNumber);
    deviceService
      .deleteDevicesIds(this.selectedDevices)
      .then(() => {
        this.$store.dispatch("snackbarInfo", {
          active: true,
          text: this.$t("deviceDetails.deleteDeviceInstallation.completed"),
        });
      })

      .catch((err) => {
        displayErrorMessage(err, {
          general: ["general.error.refreshError"],
        });
      })
      .finally(() => {
        this.refreshDevice(false);
        this.showConfirmDeleteInstallation = false;
      });
    this.selectedDevices = [];
  }

  async openEventHistory() {
    this.isLoadingEvents = true;
    this.pageNumberEvent = 0;
    this.eventsHistory = [];
    this.showEventHistory = true;
    await this.loadEvents();
    this.isLoadingEvents = false;
  }

  loadMoreEvents(page) {
    this.pageNumberEvent = page;
    this.loadEvents();
  }

  loadEvents() {
    return rexistroService
      .getEventHistory(
        "deviceId=" +
          this.deviceId +
          "&startDateHistory=" +
          this.startDateHistory +
          "&endDateHistory=" +
          this.endDateHistory,
        this.pageNumberEvent
      )
      .then((res) => {
        this.totalPagesEvent = res.data.totalPages;
        this.totalSizeEvent = res.data.totalElements;
        this.eventsHistory.push(...res.data.content);
      })
      .catch((err) => {
        displayErrorMessage(err, {
          general: ["general.error.errorGetInfo"],
        });
        throw err;
      });
  }

  qrCode() {
    this.url = QR_CODE_PATH + this.deviceCacheDetails.serialNumber;
    this.showQrCode = true;
  }
  public onReloadPaired() {
    this.$refs.logSupportComponent.reload();
  }
}
</script>

<style scoped>
.device-map {
  height: calc(100% - 61px);
}
.map-button-full-screen {
  margin: -15px 0px -15px 0px;
  color: rgb(0, 0, 0, 0.54) !important;
}
.device-image-zoom {
  overflow: hidden;
  position: relative;
}
.device-image-zoom .v-responsive.v-image {
  width: 110%;
  max-width: 100vw;
  margin-left: -5%;
}
</style>
