import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import FrmxDialog from "@/ui/components/FrmxDialog/FrmxDialog.vue";
import PairingDeviceEntity from "@/domain/pairing/PairingDeviceEntity";
import DateHelper from "@/ui/helpers/DateHelper";
import router from "@/router";
import AppListMenu from "@/components/AppListMenu.vue";

@Component({
  components: { FrmxDialog, AppListMenu },
})
export default class SubscriptionDetailsDialog extends Vue {
  @Prop({ default: false }) public value!: boolean;
  @Prop() public pairing!: PairingDeviceEntity;

  public visibleDialog = false;
  dateHelper = new DateHelper();

  @Watch("value")
  private onValueChange(value: boolean) {
    this.visibleDialog = value;
  }

  @Watch("visibleDialog")
  private onVisibleDialogChange(value: boolean) {
    this.$emit("input", value);
  }

  public onClickPlanDetails() {
    router.push("plan-" + this.pairing.getPlanId());
  }

  get menuOptions() {
    return [
      {
        icon: "cancel",
        text: this.$t("userdetails.subscriptionInfo.cancelSubscription"),
        action: () => {
          console.log("a");
        },
        allowed: this.canCancelSubscription(),
      },
    ];
  }

  private canCancelSubscription() {
    return false;
    /*return (
      this.$ability.can("changeSubscription", "users") &&
      this.pairing.getSku() !== "fullservice-annual-wifi" &&
      !this.pairing.isCanceled()
    );*/
  }
}
