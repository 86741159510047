import { render, staticRenderFns } from "./DelSupportDialog.html?vue&type=template&id=df2bd72a&scoped=true&lang=html&external"
import script from "./DelSupportDialog.ts?vue&type=script&lang=ts&external"
export * from "./DelSupportDialog.ts?vue&type=script&lang=ts&external"
import style0 from "./DelSupportDialog.scss?vue&type=style&index=0&id=df2bd72a&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "df2bd72a",
  null
  
)

export default component.exports