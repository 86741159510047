import UserService from "./UserService";

export default class UserController {
  protected service: UserService;

  constructor() {
    this.service = new UserService();
  }

  public async getUserIdByEmail(email: string): Promise<string> {
    return this.service.getUserIdByEmail(email);
  }
}
