import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import FrmxDialog from "@/ui/components/FrmxDialog/FrmxDialog.vue";
import PairingController from "@/domain/pairing/PairingController";
import { AxiosError } from "axios";
import FrmxInputText from "@/ui/components/FrmxInputText/FrmxInputText.vue";

@Component({
  components: { FrmxDialog, FrmxInputText },
})
export default class DelSupportDialog extends Vue {
  @Prop({ default: "" }) public pairingId!: string;
  @Prop({ default: "" }) public tag!: string;
  @Prop({ default: false }) public value!: boolean;

  public visibleDialog = false;
  public visibleDialogOk = false;
  public visibleDialogKo = false;
  public errorMessage = "";
  public loading = false;

  @Watch("value")
  private onValueChange(value: boolean) {
    this.visibleDialog = value;
    if (this.visibleDialog === false) {
      this.resetValues();
    }
  }

  @Watch("visibleDialog")
  private onVisibleDialogChange(value: boolean) {
    this.$emit("input", value);
  }

  public onClickCancel() {
    this.onVisibleDialogChange(false);
  }

  public onClickDel() {
    this.loading = true;
    this.validateReason();
    if (this.isFormOk()) {
      const ctrl = new PairingController();

      ctrl
        .delPairedUser(this.pairingId, this.reasonValue)
        .then(() => {
          this.onVisibleDialogChange(false);
          this.visibleDialogOk = true;
          this.$emit("del");
        })
        .catch(
          (
            error: AxiosError<{
              title: string;
              status: number;
              detail: string;
            }>
          ) => {
            this.errorMessage = error.response?.data.detail ?? "";
            this.onVisibleDialogChange(false);
            this.visibleDialogKo = true;
            console.error("Error al eliminar el invitado:", error);
          }
        )
        .finally(() => {
          this.loading = false;
        });
    } else {
      this.loading = false;
    }
  }

  private resetValues() {
    this.reasonValue = "";
    this.reasonErrors = [];
  }

  private isFormOk() {
    return this.reasonErrors.length === 0;
  }

  // #region Motivo de la invitación.
  public reasonValue = "";
  public reasonErrors: Array<string> = [];

  public onChangeReason() {
    this.validateReason();
  }

  public validateReason() {
    if (!this.reasonValue) {
      this.reasonErrors = [this.$t("general.inputErrors.required").toString()];
    } else {
      this.reasonErrors = [];
    }
  }
  // #endregion

  public onClickClose() {
    this.visibleDialogOk = false;
    this.visibleDialogKo = false;
  }
}
