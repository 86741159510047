import { Vue, Component, Prop } from "vue-property-decorator";
import PairingStatus from "../PairingStatus/PairingStatus.vue";
import DateHelper from "@/ui/helpers/DateHelper";
import AppListMenu from "@/components/AppListMenu.vue";
import AddUserDialog from "./AddUserDialog/AddUserDialog.vue";
import AddSupportDialog from "./AddSupportDialog/AddSupportDialog.vue";
import AddMasterDialog from "./AddMasterDialog/AddMasterDialog.vue";
import DelUserDialog from "./DelUserDialog/DelUserDialog.vue";
import DelSupportDialog from "./DelSupportDialog/DelSupportDialog.vue";
import DelMasterDialog from "./DelMasterDialog/DelMasterDialog.vue";
import PairingUserEntity from "@/domain/pairing/PairingUserEntity";
import PairingController from "@/domain/pairing/PairingController";

@Component({
  components: {
    PairingStatus,
    AppListMenu,
    AddUserDialog,
    AddSupportDialog,
    AddMasterDialog,
    DelUserDialog,
    DelSupportDialog,
    DelMasterDialog,
  },
})
export default class PairedUserTable extends Vue {
  @Prop({ default: "" }) public deviceId!: string;
  dateHelper = new DateHelper();
  visibleAddUserDialog = false;
  visibleAddSupportDialog = false;
  visibleAddMasterDialog = false;
  visibleDelUserDialog = false;
  visibleDelSupportDialog = false;
  visibleDelMasterDialog = false;
  public masterId = "";
  public masterEmail = "";
  public isTypeFree = true;

  /** Registros de la tabla. */
  public dataTable: PairingUserEntity[] = [];
  /** Indica si la tabla está cargando datos. */
  public dataTableLoading = false;

  public showOptions = false;

  mounted() {
    this.loadData();
  }

  /** Carga los datos de la tabla. */
  private async loadData() {
    this.dataTableLoading = true;
    const ctrl = new PairingController();
    const data = await ctrl.getPairingByDeviceId(this.deviceId);
    // Ordena los datos para tener el master en la parte superior.
    data.sort((a, b) => (a.master === b.master ? 0 : a.master ? -1 : 1));
    // Obtiene el id del master.
    const master: PairingUserEntity | undefined = data.find(
      (pairing) => pairing.master
    );

    this.masterId = master?.userId || "";
    this.masterEmail = master?.userEmail || "";
    this.isTypeFree = master?.isTypeFree() ?? true;

    this.$emit("changeMaster", master);

    this.dataTable = data;
    this.dataTableLoading = false;
  }

  /**
   * Obtiene las opciones de la tabla.
   * De forma reactiva para que se traduzcan al cambiar el idioma.
   */
  get menuOptions() {
    return [
      {
        icon: "group",
        text: this.$t("deviceDetails.pairedUsers.addGuest"),
        action: () => (this.visibleAddUserDialog = true),
        allowed: true,
      },
      {
        icon: "support_agent",
        text: this.$t("deviceDetails.pairedUsers.addSupport"),
        action: () => (this.visibleAddSupportDialog = true),
        allowed: true,
      },
    ];
  }

  public pairingIdDelUserDialog = "";
  public tagDelUserDialog = "";
  public emailDelUserDialog = "";
  public onClickDeletePairing(item: {
    id: string;
    tag: string;
    userEmail: string;
    master: boolean;
    guestType: string;
  }) {
    this.pairingIdDelUserDialog = item.id;
    this.tagDelUserDialog = item.tag;
    this.emailDelUserDialog = item.userEmail;
    if (item.master) {
      this.visibleDelMasterDialog = true;
    } else if (item.guestType === "TECHNICIAN") {
      this.visibleDelSupportDialog = true;
    } else {
      this.visibleDelUserDialog = true;
    }
  }

  public onAddUser() {
    this.reloadPairings();
  }

  public onAddSupport() {
    this.reloadPairings();
  }

  public onAddMaster() {
    this.reloadPairings();
  }

  public onDelUser() {
    this.reloadPairings();
  }

  private reloadPairings() {
    this.loadData();
    this.$emit("reload");
  }

  public existsMaster() {
    return this.masterId !== "";
  }

  public showAddMaster() {
    this.visibleAddMasterDialog = true;
  }

  /**
   * Obtiene las cabeceras de la tabla.
   * De forma reactiva para que se traduzcan al cambiar el idioma.
   */
  get dataHeaders() {
    return [
      { text: this.$t("userdetails.userEmail"), sortable: false },
      { text: this.$t("userdetails.deviceTag"), sortable: false },
      { text: this.$t("userdetails.rol"), sortable: false },
      { text: this.$t("general.status"), sortable: false },
      { text: this.$t("user.pairingDate"), sortable: false },
      { text: "", sortable: false },
    ];
  }
}
