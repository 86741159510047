import DeviceEntity from "../entities/DeviceEntity";
import InstallationPenetrationEntity from "../installation/InstallationPenetrationEntity";
import InstallationService from "../services/InstallationService";

/**
 * @deprecated Esta clase está en desuso y se eliminará en versiones futuras.
 * Utilice domain/installation/installationController en su lugar.
 */
export default class InstallationController {
  protected installationService: InstallationService;
  constructor() {
    this.installationService = new InstallationService();
  }

  /** Obtiene si el dispositivo tiene el servicio de llamadas activo o no. */
  public async getCallDivert(deviceEntity: DeviceEntity): Promise<boolean> {
    const installationId = deviceEntity.installationId;
    const logicalId = deviceEntity.getLogisticId();
    if (logicalId === "") {
      return false;
    }
    return await this.installationService
      .getCallDivert(installationId, logicalId)
      .then((response: boolean) => {
        return response;
      });
  }

  /** Obtiene los dispositivos emparejados. */
  public async getPairedDevices(deviceEntity: DeviceEntity): Promise<string[]> {
    const serialNumberDevice = deviceEntity.serialNumber;
    return await this.installationService
      .getPairedDevices(serialNumberDevice)
      .then((response: string[]) => {
        return response;
      });
  }

  public async getPenetration(
    id: string
  ): Promise<InstallationPenetrationEntity> {
    const data = await this.installationService.getPenetration(id);
    return new InstallationPenetrationEntity(data);
  }
}
