import { SubscriptionInterface } from "../Subscription/SubscriptionInterface";
import { SubscriptionNoWifiInterface } from "./SubscriptionNoWifiInterface";

export default class SubscriptionNoWifiEntity implements SubscriptionInterface {
  public id: string;
  public code: string;
  public type: string;
  public createDate: string;
  public expiredDate: string;
  public trial: true;
  public trialEnded: true;
  public freeRenewalDate: string;
  public product: {
    productId: string;
    price: number;
    currency: string;
    duration: string;
    state: string;
    updateDate: string;
  };
  public features: [
    {
      feature: string;
      value: number;
      current: number;
    }
  ];

  constructor(data: SubscriptionNoWifiInterface) {
    this.id = data.id;
    this.code = data.code;
    this.type = data.type;
    this.createDate = data.createDate;
    this.expiredDate = data.expiredDate;
    this.trial = data.trial;
    this.trialEnded = data.trialEnded;
    this.freeRenewalDate = data.freeRenewalDate;
    this.product = data.product;
    this.features = data.features;
  }

  public getCommercialName(language: string): string {
    if (this.isPlanTrial()) {
      if (language === "es") {
        return "Periodo de prueba";
      } else {
        return "Trial";
      }
    }
    if (this.isTypeFree()) {
      if (language === "es") {
        return "Plan gratuito";
      } else {
        return "Free";
      }
    }
    // Esa plan ilimitado anual o mensual.
    if (this.product) {
      if (this.isPlanAnnual()) {
        if (language === "es") {
          return "Plan ilimitado anual";
        } else {
          return "Unlimited Year";
        }
      }
      if (this.isPlanMonthly()) {
        if (language === "es") {
          return "Plan ilimitado mensual";
        } else {
          return "Unlimited Month";
        }
      }
    }
    // Aquí no debe de llegar ya que esto es para los WIFI.
    if (language === "es") {
      return "Ilimitado";
    } else {
      return "Unlimited";
    }
  }

  /** Indica si el plan de la subscripción es de tipo trial. */
  public isPlanTrial(): boolean {
    return this.trial;
  }

  /** Indica si el tipo es FREE, puede ser FREE o UNLIMITED. */
  public isTypeFree(): boolean {
    return this.type === "FREE";
  }

  public getPrice(): number {
    return this.product?.price;
  }

  public getCurrency(): string {
    return this.product?.currency;
  }

  /** Obtiene si el plan es anual. */
  public isPlanAnnual(): boolean {
    return (
      !this.isPlanFree() &&
      !this.isPlanTrial() &&
      this.product?.duration === "1y"
    );
  }

  /** Obtiene si el plan es mensual. */
  public isPlanMonthly(): boolean {
    return (
      !this.isPlanFree() &&
      !this.isPlanTrial() &&
      this.product?.duration === "1m"
    );
  }
  /** Fecha de inicio de pago por la suscripción. */
  getStartDate(): Date | undefined {
    return this.product?.updateDate
      ? new Date(this.product?.updateDate)
      : undefined;
  }
  /** Fecha de creación del emparejamiento. */
  getCreateDate(): Date | undefined {
    return this.createDate ? new Date(this.createDate) : undefined;
  }
  getExpiredDate(): Date | undefined {
    return this.expiredDate ? new Date(this.expiredDate) : undefined;
  }
  /** Fecha de renovación de las limitadores de la suscripción gratuita. */
  getFreeRenewedDate(): Date | undefined {
    return this.freeRenewalDate ? new Date(this.freeRenewalDate) : undefined;
  }

  isUnlimited(): boolean {
    return false;
  }
  /** Solo los WIFI tienen identificador de la suscripción. */
  getPlanId(): number | undefined {
    return undefined;
  }
  isPlanFree(): boolean {
    return !this.isPlanTrial() && this.isTypeFree();
  }
  isPlanUnlimited(): boolean {
    return false;
  }
}
