import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import FrmxDialog from "@/ui/components/FrmxDialog/FrmxDialog.vue";
import SubscriptionNoWifiController from "@/domain/subscriptionNoWifi/SubscriptionNoWifiController";
import { AxiosError } from "axios";

@Component({
  components: { FrmxDialog },
})
export default class ExtendTrialDialog extends Vue {
  @Prop({ default: false }) public value!: boolean;
  @Prop({ default: "" }) public logicalId!: string;
  @Prop({ default: "" }) public expiredDate!: string;

  public visibleDialog = false;
  public visibleDialogConfirm = false;
  public visibleDialogOk = false;
  public visibleDialogko = false;
  public errorMessage = "";
  public loading = false;

  /** Valor por defecto de 30 días. */
  public picker = "";
  /** Valor mínimo los 30 días que hay por defecto. */
  public minPicker = this.picker;
  /** Valor máximo un año desde la fecha actual. */
  public maxPicker = this.get365Days();

  @Watch("value")
  private onValueChange(value: boolean) {
    this.visibleDialog = value;
  }

  @Watch("expiredDate")
  private onExpiredDateChange(value: string) {
    this.picker = value;
    this.minPicker = this.picker;
  }

  @Watch("visibleDialog")
  private onVisibleDialogChange(value: boolean) {
    this.$emit("input", value);
  }

  mounted() {
    this.visibleDialog = this.value;
    this.picker = this.expiredDate;
    this.minPicker = this.picker;
  }

  public onClickClose() {
    this.close();
  }

  public onClickSave() {
    this.onVisibleDialogChange(false);
    this.visibleDialogConfirm = true;
  }

  public onClickCancel() {
    this.close();
  }

  public onClickConfirm() {
    this.loading = true;
    const ctrl = new SubscriptionNoWifiController();
    ctrl
      .extendTrial(this.logicalId, this.picker)
      .then(() => {
        this.visibleDialogConfirm = false;
        this.visibleDialogOk = true;
        this.$emit("trialExtended");
      })
      .catch(this.activeTrialHandleError)
      .finally(() => {
        this.loading = false;
      });
  }

  private close() {
    this.onVisibleDialogChange(false);
    this.visibleDialogConfirm = false;
    this.visibleDialogOk = false;
    this.visibleDialogko = false;
  }

  /** Manejar errores al añadir un invitado. */
  private activeTrialHandleError(
    error: AxiosError<{
      title: string;
      status: number;
      detail: string;
    }>
  ) {
    this.errorMessage = "";
    if (error.response?.data.status === 500) {
      this.errorMessage = "Internal server error. Please try again later.";
    } else {
      this.errorMessage = error.response?.data.detail ?? "";
    }
    this.visibleDialogConfirm = false;
    this.visibleDialogko = true;
  }

  /**
   * Obtener la fecha actual más 30 días.
   * En formato YYYY-MM-DD.
   */
  private get30Days(): string {
    return new Date(new Date().setDate(new Date().getDate() + 30))
      .toISOString()
      .split("T")[0];
  }

  /**
   * Obtener la fecha actual más 365 días.
   * En formato YYYY-MM-DD.
   */
  private get365Days(): string {
    return new Date(new Date().setDate(new Date().getDate() + 365))
      .toISOString()
      .split("T")[0];
  }

  /**
   * Calcular la diferencia de días entre la fecha actual y la fecha de expiración.
   * @param expiredDateISO Fecha de expiración en formato ISO extendido (YYYYYY-MM-DD).
   */
  private getDaysDiff(expiredDateISO: string): number {
    const currentDate = new Date();
    const expiredDate = new Date(expiredDateISO);
    const differenceInTime = expiredDate.getTime() - currentDate.getTime();
    const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));
    return differenceInDays;
  }
}
