import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class DialogOk extends Vue {
  @Prop({ default: "" }) public title!: string;
  @Prop({ default: "" }) public content!: string;
  @Prop({ default: false }) public showDialog!: boolean;

  // Crea una propiedad de datos para manejar el valor de la prop
  private dialogVisible = this.showDialog;

  private closeDialog() {
    this.$emit("update:showDialog", this.dialogVisible);
  }
}
