import SubscriptionWifiEntity from "./SubscriptionWifiEntity";
import SubscriptionWifiService from "./SubscriptionWifiService";

export default class SubscriptionWifiController {
  protected service: SubscriptionWifiService;

  constructor() {
    this.service = new SubscriptionWifiService();
  }

  /**
   * Obtiene la suscripción de un dispositivo y un usuario.
   * @param deviceId Id del dispositivo.
   * @param userId Id del usuario.
   */
  public async getSubscriptionByDeviceIdUserId(
    deviceId: string,
    userId: string
  ): Promise<SubscriptionWifiEntity> {
    return this.service
      .getSubscriptionByDeviceIdUserId(deviceId, userId)
      .then((data) => {
        if (data) {
          return new SubscriptionWifiEntity(data);
        } else {
          return Promise.reject("Subscription not found");
        }
      });
  }
}
