import PairingBaseEntity from "./PairingBaseEntity";
import {
  PairingBaseInterface,
  PairingInstallationInterface,
} from "./PairingInterface";
import moment from "moment";

export default class PairingInstallationEntity extends PairingBaseEntity {
  public address: string;
  public numUnit: number;

  constructor(data: PairingInstallationInterface) {
    const superInterface: PairingBaseInterface = {
      id: data.id,
      userId: data.userId,
      userEmail: data.userEmail,
      type: data.type,
      deviceId: data.deviceId,
      subscriptionNoWifi: data.subscriptionNoWifi,
      guest: data.guest,
      master: data.master,
      logicalId: data.logicalId,
    };
    super(superInterface);
    this.address = data.address;
    this.numUnit = data.numUnit;
  }

  get subscriptionPlanName(): string {
    return super.getSubscriptionPlanName();
  }

  get deviceNameForTable(): string {
    return super.getDeviceName();
  }

  /*get rol(): string {
    return TranslateText.t(super.getRolCodeT());
  }*/

  public getExpirationDateFormat(): string {
    const date: Date | undefined = super.getExpirationDate();
    return date ? moment(date).format("DD/MM/YYYY") : "";
  }

  /**
   * Obtiene la fecha de vencimiento de la suscripción en formato "DD/MM/YYYY".
   * Si no tiene fecha de vencimiento, devuelve "-".
   */
  public getExpiredDateFormat(): string {
    const date = super.getExpirationDate();
    return date ? moment(date).format("DD/MM/YYYY") : "-";
  }

  public getFreeRenewedDateFormat(): string {
    const date = super.getFreeRenewedDate();
    return date ? moment(date).format("DD/MM/YYYY") : "-";
  }
}
