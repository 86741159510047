import moment from "moment";
import {
  pairingGuestType,
  PairingLogBaseInterface,
  PairingLogsubtype,
  PairingLogType,
} from "./PairingInterface";

export default class PairingLogBaseEntity {
  private time: number;
  public dateLog: string;
  public type: PairingLogType;
  public subtype: PairingLogsubtype;
  public createdBy: string;
  private guestType: pairingGuestType;

  public constructor(data: PairingLogBaseInterface) {
    this.time = data.time;
    this.dateLog = moment(this.time).format("DD/MM/YYYY HH:mm:ss");
    this.type = data.type;
    this.subtype = data.subtype;
    this.createdBy = data.requestedBy;
    this.guestType = data.guestType;
  }

  /** Es un emparejamiento. */
  protected isPaired(): boolean {
    return this.subtype === "PAIRED";
  }

  /** Es un desemparejamiento. */
  protected isUnpaired(): boolean {
    return this.subtype === "UNPAIRED";
  }

  /** El log lo ha creado un usuario de soporte. */
  protected isSupport(): boolean {
    return this.guestType === "TECHNICIAN";
  }

  /** Extensión de periodo de prueba. */
  protected isExtendTrial(): boolean {
    return this.subtype === "extend.trial";
  }

  /** Activación de periodo de prueba. */
  protected isActivateTrial(): boolean {
    return this.subtype === "activate.trial";
  }
}
