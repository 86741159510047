import { render, staticRenderFns } from "./BreadCrumb.html?vue&type=template&id=bfc90782&scoped=true&lang=html&external"
import script from "./BreadCrumb.ts?vue&type=script&lang=ts&external"
export * from "./BreadCrumb.ts?vue&type=script&lang=ts&external"
import style0 from "./BreadCrumb.scss?vue&type=style&index=0&id=bfc90782&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bfc90782",
  null
  
)

export default component.exports