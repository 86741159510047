import { AxiosHelper } from "../helpers/AxiosHelper";
import { SubscriptionWifiInterface } from "./SubscriptionWifiInterface";

export default class SubscriptionWifiService {
  protected axiosService: AxiosHelper;
  protected urlBase: string;

  constructor() {
    this.axiosService = AxiosHelper.getInstance();
    this.urlBase =
      process.env.VUE_APP_PRE_URI + "/subscription/api/v1/subscriptions";
  }

  public async getSubscriptionByDeviceIdUserId(
    deviceId: string,
    userId: string
  ): Promise<SubscriptionWifiInterface> {
    let url = this.urlBase;
    url = url + "/device/" + deviceId;
    url = url + "?active=true&addCancelInformation=true";
    url = url + "&userId=" + userId;
    return this.axiosService.get(url).then((response) => {
      return response.length > 0 ? response[0] : null;
    });
  }
}
