import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import FrmxDialog from "@/ui/components/FrmxDialog/FrmxDialog.vue";
import PairingDeviceEntity from "@/domain/pairing/PairingDeviceEntity";

@Component({
  components: { FrmxDialog },
})
export default class PhoneDetailsDialog extends Vue {
  @Prop({ default: false }) public value!: boolean;
  @Prop() public pairing!: PairingDeviceEntity;

  public visibleDialog = false;

  @Watch("value")
  private onValueChange(value: boolean) {
    this.visibleDialog = value;
  }

  @Watch("visibleDialog")
  private onVisibleDialogChange(value: boolean) {
    this.$emit("input", value);
  }
}
