import { SubscriptionInterface } from "../Subscription/SubscriptionInterface";
import {
  SubscriptionWifiInterface,
  SubscriptionWifiPlanAdvancedInterface,
} from "./SubscriptionWifiInterface";

export default class SubscriptionWifiEntity implements SubscriptionInterface {
  public id: string;
  public cancelAt: number;
  public cancellationReasonSubDTO: {
    reason: string;
  };
  public planAdvancedDTO: SubscriptionWifiPlanAdvancedInterface;
  public initialDate: number;
  public endDate: number;

  constructor(data: SubscriptionWifiInterface) {
    this.id = data.id;
    this.cancelAt = data.cancelAt;
    this.cancellationReasonSubDTO = data.cancellationReasonSubDTO;
    this.planAdvancedDTO = data.planAdvancedDTO;
    this.initialDate = data.initialDate;
    this.endDate = data.endDate;
  }

  public getCommercialName(language: string): string {
    let commercialName = this.planAdvancedDTO.regionalDetailsList.find(
      (regionalDetails) =>
        regionalDetails.language.toLowerCase() === language.toLowerCase()
    )?.commercialName;
    if (
      !commercialName &&
      this.planAdvancedDTO.regionalDetailsList.length > 0
    ) {
      commercialName =
        this.planAdvancedDTO.regionalDetailsList[0].commercialName;
    }
    return commercialName || "";
  }

  public getPrice() {
    return this.planAdvancedDTO.availabilityRegionList[0].price;
  }

  public getCurrency() {
    return this.planAdvancedDTO.availabilityRegionList[0].currency;
  }

  /** Identificador de la subscripción. */
  public getPlanId() {
    return this.planAdvancedDTO.planId;
  }

  isTypeFree(): boolean {
    return false;
  }
  /** Fecha de inicio de pago por la suscripción. */
  getStartDate(): Date | undefined {
    return this.initialDate ? new Date(this.initialDate) : undefined;
  }
  /** Fecha de creación del emparejamiento. */
  getCreateDate(): Date | undefined {
    return this.getStartDate();
  }
  /** Fecha de vencimiento de la suscripción. */
  getExpiredDate(): Date | undefined {
    return undefined;
  }
  /** Fecha de renovación de las limitadores de la suscripción gratuita. */
  getFreeRenewedDate(): Date | undefined {
    return undefined;
  }
  isUnlimited(): boolean {
    return false;
  }
  isPlanFree(): boolean {
    return false;
  }
  isPlanTrial(): boolean {
    return false;
  }
  isPlanAnnual(): boolean {
    return false;
  }
  isPlanMonthly(): boolean {
    return false;
  }
  isPlanUnlimited(): boolean {
    return true;
  }
}
