import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import FrmxDialog from "@/ui/components/FrmxDialog/FrmxDialog.vue";
import { DeviceAddressInterface } from "@/domain/interfaces/DeviceInterface";
import i18nCountryHelper from "@/ui/helpers/i18nCountryHelper";

@Component({
  components: { FrmxDialog },
})
export default class AddressDialog extends Vue {
  @Prop({ default: false }) public value!: boolean;
  @Prop({
    default: {
      address: "",
      zipCode: "",
      location: "",
      country: "",
      province: "",
    },
  })
  public address!: DeviceAddressInterface;

  public visibleDialog = false;

  @Watch("value")
  private onValueChange(value: boolean) {
    this.visibleDialog = value;
  }

  @Watch("visibleDialog")
  private onVisibleDialogChange(value: boolean) {
    this.$emit("input", value);
  }

  public onClickCancel() {
    this.onVisibleDialogChange(false);
  }

  mounted() {
    this.visibleDialog = this.value;
  }

  // Para obtener el nombre del país a partir del código.
  private i18nCountry = i18nCountryHelper.getInstance();
  public getCountryName(countryCode: string) {
    return this.i18nCountry.getCountryName(countryCode);
  }
}
