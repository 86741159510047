import { AxiosHelper } from "../helpers/AxiosHelper";
import { DeviceCacheReaderInterface } from "./DeviceCacheReaderInterface";

export default class deviceCacheReader {
  protected axiosService: AxiosHelper;
  protected urlBase: string;

  constructor() {
    this.axiosService = AxiosHelper.getInstance();
    this.urlBase = process.env.VUE_APP_PRE_URI + "/devicecachereader/api/v1";
  }

  public async getDeviceById(id: string): Promise<DeviceCacheReaderInterface> {
    const url = this.urlBase + "/devices/" + id;
    return this.axiosService.get(url);
  }
}
