import { AxiosHelper } from "../helpers/AxiosHelper";
import { InstallationPenetrationInterface } from "../installation/InstallationInterface";

/**
 * @deprecated Esta clase está en desuso y se eliminará en versiones futuras.
 * Utilice domain/installation/installationService en su lugar.
 */
export default class InstallationService {
  protected axiosService: AxiosHelper;
  protected urlBase: string;
  constructor() {
    this.axiosService = AxiosHelper.getInstance();
    this.urlBase = process.env.VUE_APP_PRE_URI + "/installation/api/v1";
  }

  /** Obtiene si el dispositivo tiene el servicio de llamadas activo o no. */
  public async getCallDivert(
    installationId: string,
    logicalId: string
  ): Promise<boolean> {
    const url =
      this.urlBase +
      "/installation/devices/calldivert/installation/" +
      installationId +
      "?logicalId=" +
      logicalId;
    return this.axiosService
      .get(url)
      .then((response: { callDivertActive: boolean }) => {
        return response.callDivertActive;
      });
  }

  /** Obtiene los dispositivos emparejados. */
  public async getPairedDevices(serialNumberDevice: string): Promise<string[]> {
    const url =
      this.urlBase +
      "/installation/devices/calldivert/panel/" +
      serialNumberDevice;
    return this.axiosService
      .get(url)
      .then((response: { logicalIdList: string[] }) => {
        return response.logicalIdList;
      });
  }

  public async getPenetration(
    id: string
  ): Promise<InstallationPenetrationInterface> {
    const url = this.urlBase + "/installations/" + id + "/penetration";
    return this.axiosService.get(url);
  }
}
