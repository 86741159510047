import DeviceCacheReaderEntity from "../deviceCacheReader/DeviceCacheReaderEntity";
import { SubscriptionInterface } from "../Subscription/SubscriptionInterface";
import SubscriptionNoWifiEntity from "../subscriptionNoWifi/SubscriptionNoWifiEntity";
import SubscriptionWifiEntity from "../subscriptionWifi/SubscriptionWifiEntity";
import { PairingBaseInterface } from "./PairingInterface";

export default class PairingBaseEntity {
  public id: string;
  public userId: string;
  public userEmail: string;
  public type: "WIFI" | "NOWIFI";
  public deviceId: string;
  private subscription: SubscriptionInterface | undefined;
  private deviceCacheReader: DeviceCacheReaderEntity | undefined;
  public guest: boolean;
  public master: boolean;
  public logicalId: string;

  protected constructor(data: PairingBaseInterface) {
    this.id = data.id;
    this.userId = data.userId;
    this.userEmail = data.userEmail;
    this.type = data.type;
    this.deviceId = data.deviceId;

    this.subscription = undefined;
    if (data.subscriptionNoWifi) {
      this.subscription = new SubscriptionNoWifiEntity(data.subscriptionNoWifi);
    }

    this.deviceCacheReader = undefined;

    this.guest = data.guest;
    this.master = data.master;
    this.logicalId = data.logicalId;
  }

  public get email() {
    return this.userEmail;
  }

  /** El emparejamiento es de tipo WIFI. */
  public isWifi() {
    return this.type === "WIFI";
  }

  /** El emparejamiento es de tipo NO WIFI. */
  public isNoWifi() {
    return this.type === "NOWIFI";
  }

  public isMaster() {
    return this.master;
  }

  public isTypeFree(): boolean {
    return this.subscription?.isTypeFree() ?? false;
  }

  /** Asigna la información de la suscripción WIFI. */
  public setSubscriptionWifi(subscriptionWifi: SubscriptionWifiEntity) {
    this.subscription = subscriptionWifi;
  }

  /** Asigna la información del dispositivo. */
  public setDeviceCacheReader(data: DeviceCacheReaderEntity) {
    this.deviceCacheReader = data;
  }

  /**
   * Obtiene el nombre del plan de la suscripción.
   * @param language Idioma en el que se desea obtener el nombre del plan.
   */
  public getSubscriptionPlanName(language = "es"): string {
    return this.subscription?.getCommercialName(language) ?? "-";
  }

  /** Obtiene el nombre del dispositivo. */
  public getDeviceName(): string {
    let r = "";
    if (this.deviceCacheReader) {
      r +=
        this.deviceCacheReader.family.charAt(0).toUpperCase() +
        this.deviceCacheReader.family.slice(1).toLowerCase();
      r += " ";
      r += this.deviceCacheReader.type.toUpperCase();
      r += " - ";
      if (this.deviceCacheReader.subtype === "WIFI") {
        r += "Wi-Fi";
      } else if (this.deviceCacheReader.subtype === "NOWIFI") {
        r += "No Wi-Fi";
      } else {
        r += this.deviceCacheReader.subtype;
      }
    }
    return r;
  }

  /** Obtiene la fecha de renovación de las limitadores de la suscripción gratuita. */
  public getFreeRenewedDate(): Date | undefined {
    return this.subscription?.getFreeRenewedDate() ?? undefined;
  }

  public isMonitor(): boolean {
    return this.deviceCacheReader?.isMonitor() ?? false;
  }

  public isPhone(): boolean {
    return this.deviceCacheReader?.isPhone() ?? false;
  }

  public isGuardUnit(): boolean {
    return this.deviceCacheReader?.isGuardUnit() ?? false;
  }

  public getDeviceType(): string {
    return this.deviceCacheReader?.type ?? "";
  }

  protected getNameIcon(): string {
    return this.deviceCacheReader?.getNameIcon() ?? "";
  }

  public existsSubscription(): boolean {
    return this.subscription !== undefined;
  }

  /** Solo los dispositivos WIFI tienen identificador de plan de suscripción. */
  getPlanId(): number | undefined {
    return this.subscription?.getPlanId() ?? undefined;
  }

  /**
   * Obtiene el precio de la suscripción,
   * si no tiene precio devuelve 0.
   * @returns Precio de la suscripción.
   */
  protected getPrice(): number {
    return this.subscription?.getPrice() ?? 0;
  }

  /**
   * Obtiene la moneda de la suscripción,
   * si no tiene moneda devuelve "EUR".
   * @returns Moneda de la suscripción.
   */
  protected getCurrency(): string {
    return this.subscription?.getCurrency() ?? "EUR";
  }

  public getRolCodeT(): string {
    return this.isMaster()
      ? "deviceDetails.pairedUsers.roles.master"
      : "deviceDetails.pairedUsers.roles.guest";
  }

  public existsDeviceCacheReader(): boolean {
    return this.deviceCacheReader !== undefined;
  }

  /** Obtiene si el plan es gratuito. */
  public isPlanFree(): boolean {
    return this.subscription?.isPlanFree() ?? false;
  }

  /** Obtiene si el plan es de prueba. */
  public isPlanTrial(): boolean {
    return this.subscription?.isPlanTrial() ?? false;
  }

  /** Obtiene si el plan es anual. */
  public isPlanAnnual(): boolean {
    return this.subscription?.isPlanAnnual() ?? false;
  }

  /** Obtiene si el plan es mensual. */
  public isPlanMonthly(): boolean {
    return this.subscription?.isPlanMonthly() ?? false;
  }

  /** Obtiene si el plan es ilimitado. */
  public isPlanUnlimited(): boolean {
    return this.subscription?.isPlanUnlimited() ?? false;
  }

  /** Obtiene la fecha de finalización */
  public getExpirationDate(): Date | undefined {
    return this.subscription?.getExpiredDate() ?? undefined;
  }

  /** Obtiene la fecha de inicio de la suscripción. */
  public getStartDate(): Date | undefined {
    return this.subscription?.getStartDate() ?? undefined;
  }

  /** Obtiene la fecha de creación del emparejamiento. */
  public getCreateDate(): Date | undefined {
    return this.subscription?.getCreateDate() ?? undefined;
  }
}
