import DeviceCacheReaderEntity from "./DeviceCacheReaderEntity";
import DeviceCacheReaderService from "./DeviceCacheReaderService";

export default class DeviceCacheReaderController {
  protected service: DeviceCacheReaderService;

  constructor() {
    this.service = new DeviceCacheReaderService();
  }

  public async getDeviceById(id: string): Promise<DeviceCacheReaderEntity> {
    const data = await this.service.getDeviceById(id);
    return new DeviceCacheReaderEntity(data);
  }
}
