import PairingBaseEntity from "./PairingBaseEntity";
import { PairingBaseInterface, PairingUserInterface } from "./PairingInterface";

export default class PairingUserEntity extends PairingBaseEntity {
  public tag: string;
  public guest: boolean;
  public master: boolean;
  public status: string;
  public createdAt: string;
  public guestType: string;
  public disabled: boolean;
  public address: string;

  constructor(data: PairingUserInterface) {
    const superInterface: PairingBaseInterface = {
      id: data.id,
      userId: data.userId,
      userEmail: data.userEmail,
      type: data.type,
      deviceId: data.deviceId,
      subscriptionNoWifi: data.subscription,
      guest: data.guest,
      master: data.master,
      logicalId: data.logicalId,
    };
    super(superInterface);
    this.tag = data.tag;
    this.guest = data.guest;
    this.master = data.master;
    this.status = data.status;
    this.createdAt = data.createdAt;
    this.guestType = data.guestType;
    this.disabled = data.disabled;
    this.address = data.address;
  }

  public isTypeFree(): boolean {
    return super.isTypeFree();
  }
}
