import { AxiosHelper } from "../helpers/AxiosHelper";

export default class UserService {
  protected axiosService: AxiosHelper;
  protected urlBase: string;

  constructor() {
    this.axiosService = AxiosHelper.getInstance();
    this.urlBase = process.env.VUE_APP_PRE_URI + "/user/api/v1";
  }

  public async getUserIdByEmail(email: string): Promise<string> {
    const url = this.urlBase + "/userid?email=" + email;
    return this.axiosService.get(url);
  }
}
