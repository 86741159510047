// Cada entrada del menú puede tener:
//   title: key para obtener el texto a mostrar con el vue-i18n
//   icon: icono a mostrar
//   to: name de la ruta

const menu = [
  {
    title: "menu.dashboard",
    icon: "dashboard",
    to: { name: "Dashboard" },
  },
  {
    title: "menu.plans",
    icon: "store",
    to: { name: "Plans" },
  },
  {
    title: "menu.installations",
    icon: "device_hub",
    to: { name: "Installations" },
  },
  {
    title: "menu.devices",
    icon: "devices",
    to: { name: "Devices" },
  },
  {
    title: "menu.users",
    icon: "people",
    to: { name: "Users" },
  },
  /* {
    title: "menu.billing", 
    icon: "insert_drive_file", 
    to: "billing" 
  }, */
  {
    title: "menu.jobs",
    icon: "work",
    to: { name: "Jobs" },
  },
  {
    title: "menu.firmware",
    icon: "inbox",
    to: { name: "Firmwares" },
  },
  {
    title: "menu.oauthclients",
    icon: "apps",
    to: { name: "OAuthClients" },
  },
  {
    title: "menu.internalUsers",
    icon: "business_center",
    to: { name: "InternalUsers" },
  },
  {
    title: "menu.titleRoles",
    icon: "security",
    to: { name: "Roles" },
  },
  /*{ 
    title: "menu.titleTaboo", 
    icon: "vpn_lock", 
    to: "taboo"
  }*/
];
export default menu;
