import PairingController from "@/domain/pairing/PairingController";
import PairingLogDeviceEntity from "@/domain/pairing/PairingLogDeviceEntity";
import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class LogSupport extends Vue {
  /** Id del dispositivo. */
  @Prop({ default: "" }) public deviceId!: string;

  /** Indica si la tabla está cargando datos. */
  public loading = true;
  /** Registros de la tabla. */
  public dataTable: PairingLogDeviceEntity[] = [];

  mounted() {
    this.loadData();
  }

  /** Carga los datos de la tabla. */
  private async loadData() {
    this.loading = true;
    const ctrl = new PairingController();
    const data = await ctrl.getEventsDevice(this.deviceId);
    this.dataTable = data;
    this.loading = false;
  }

  /**
   * Recarga los datos de la tabla.
   * Se utiliza para que el padre pueda recargar los datos de la tabla.
   */
  public async reload() {
    await this.loadData();
  }

  /**
   * Obtiene las cabeceras de la tabla.
   * De forma reactiva para que se traduzcan al cambiar el idioma.
   */
  public get dataHeaders() {
    return [
      {
        text: this.$t("deviceDetails.losSupport.technicalUser"),
        sortable: false,
      },
      { text: this.$t("deviceDetails.losSupport.action"), sortable: false },
      { text: this.$t("deviceDetails.losSupport.dateTime"), sortable: false },
      { text: this.$t("deviceDetails.losSupport.email"), sortable: false },
    ];
  }
}
