import { InstallationPenetrationInterface } from "./InstallationInterface";

export default class InstallationPenetrationEntity {
  public activeSubscriptionPercentage: number;
  public numberDevicesWithActiveSubscription: number;
  public numberTotalUnits: number;
  public premiumSubscriptionPercentage: number;
  public premiumSubscriptionNumber: number;
  constructor(data: InstallationPenetrationInterface) {
    this.activeSubscriptionPercentage = data.activeSubscriptionPercentage;
    this.numberDevicesWithActiveSubscription =
      data.numberDevicesWithActiveSubscription;
    this.numberTotalUnits = data.numberTotalUnits;
    this.premiumSubscriptionPercentage = data.premiumSubscriptionPercentage;
    this.premiumSubscriptionNumber = data.premiumSubscriptionNumber;
  }
}
