import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import InputText from "primevue/inputtext";

@Component({
  components: { InputText },
})
export default class FrmxInputText extends Vue {
  /** Identificador del input. */
  @Prop({ default: "" }) public id!: string;
  /** Valor del input. */
  @Prop({ default: "" }) public value!: string;
  /** Etiqueta del input. */
  @Prop({ default: "" }) public label!: string;
  /** Placeholder del input. */
  @Prop({ default: "" }) public placeholder!: string;
  /** Mensaje de error del input. */
  @Prop({ default: "" }) public errorMessage!: string;

  public valueInput = "";

  @Watch("value")
  private onValueChange(value: string) {
    this.valueInput = value;
  }

  @Watch("valueInput")
  private onValueInputChange(value: string) {
    this.$emit("input", value);
  }

  public onChange(event) {
    this.$emit("change", event.target.value);
  }

  mounted() {
    this.valueInput = this.value;
  }
}
