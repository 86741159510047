<template>
  <v-card>
    <v-card-title>
      <h2 class="heading grey--text pr-2">{{ title }}</h2>
    </v-card-title>
    <v-card-text>
      <v-list dense class="mt-3 mb-3" v-if="events.length > 0">
        <v-list-item v-for="event in events" :key="event.time">
          <v-list-item-action v-if="$vuetify.breakpoint.lgAndUp">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <span v-on="on">
                  {{ date_format(event.time, "DD/MM/YYYY") }}:
                </span>
              </template>

              <span>{{ date_format(event.time, "DD/MM/YYYY HH:mm:ss") }}</span>
            </v-tooltip>
          </v-list-item-action>

          <v-list-item-content>
            <v-list-item-title>
              <span
                v-if="$vuetify.breakpoint.mdAndDown"
                class="date-label my-2"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <span v-on="on">
                      {{ date_format(event.time, "DD/MM/YYYY") }}:
                    </span>
                  </template>

                  <span>{{
                    date_format(event.time, "DD/MM/YYYY HH:mm:ss")
                  }}</span>
                </v-tooltip>
              </span>

              <span class="device-label mb-2" v-if="event.unitId">
                {{ event.unitId }}
              </span>
            </v-list-item-title>
            <v-list-item-subtitle class="mt-1" v-if="event.type">{{
              event.type
            }}</v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-action class="ml-2 mr-0">
            <v-btn icon @click="showInfo(event)">
              <v-icon>info</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>

        <v-list-item v-if="events.length < totalSize">
          <v-layout row justify-center align-center>
            <v-btn small color="primary" text @click="updatePage">{{
              $t("userdetails.subscriptionEvent.showMore")
            }}</v-btn>
          </v-layout>
        </v-list-item>
      </v-list>

      <v-layout
        v-else-if="isLoading"
        style="min-height: 65px"
        align-center
        justify-center
        row
      >
        <v-progress-circular indeterminate color="primary" />
      </v-layout>

      <p class="mt-2" v-else>
        {{ $t("general.notFoundEvents") }}
      </p>

      <v-dialog v-model="showEventDetails" max-width="400">
        <v-card>
          <v-card-title>
            <span class="title">{{
              $t("userdetails.subscriptionEvent.title")
            }}</span>
          </v-card-title>
          <v-card-text v-if="showEventDetailsObj">
            <v-flex class="py-2">
              <v-layout column>
                <span>{{ $t("userdetails.subscriptionEvent.date") }}</span>
                <span class="grey--text">{{
                  date_format(showEventDetailsObj.time, "DD/MM/YYYY HH:mm:ss")
                }}</span>
              </v-layout>
            </v-flex>
            <v-flex class="py-2">
              <v-layout column>
                <span>{{ $t("deviceDetails.device") }}</span>
                <span class="grey--text">{{ showEventDetailsObj.unitId }}</span>
              </v-layout>
            </v-flex>
            <v-flex class="py-2">
              <v-layout column>
                <span>{{
                  $t("userdetails.subscriptionEvent.operationType")
                }}</span>
                <span class="grey--text"
                  >{{ showEventDetailsObj.type }}
                  {{ showEventDetailsObj.subtype }}</span
                >
              </v-layout>
            </v-flex>
            <v-flex
              class="py-2"
              v-if="showEventDetailsObj.deviceId != showEventDetailsObj.unitId"
            >
              <v-layout column>
                <span>{{ $t("installationDetails.panel") }}</span>
                <span class="grey--text">{{
                  showEventDetailsObj.deviceId
                }}</span>
              </v-layout>
            </v-flex>
            <v-flex class="py-2" v-if="showEventDetailsObj.installationId">
              <v-layout column>
                <span>{{ $t("device.installationId") }}</span>
                <span class="grey--text">{{
                  showEventDetailsObj.installationId
                }}</span>
              </v-layout>
            </v-flex>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="grey"
              class="white--text"
              @click="showEventDetails = false"
            >
              {{ $t("general.close") }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-text>
  </v-card>
</template>

<!-- eslint-disable @typescript-eslint/ban-types -->
<!-- eslint-disable @typescript-eslint/no-explicit-any -->
<script lang="ts">
import { Vue, Component, Prop, Watch } from "vue-property-decorator";

import dateMixin from "@/mixins/date.mixin";

@Component({
  mixins: [dateMixin],
})
export default class AppEventList extends Vue {
  /** Todos los registros que se van a mostrar en la lista */
  @Prop(Array) events!: Array<any>;
  /** Título de la lista */
  @Prop(String) title!: string;
  /** Indica si se están cargando los registros */
  @Prop(Boolean) isLoading!: boolean;
  /** @deprecated */
  @Prop() page!: number;
  /** @deprecated */
  @Prop(Number) totalPages!: number;
  /** @deprecated */
  @Prop(Number) totalSize!: number;
  /** @deprecated */
  @Prop(Function) loadEvents!: Function;

  /** Registros que se muestran en la lista */
  public dataEvents: Array<any> = [];
  /** Página actual */
  public dataPage = 0;
  /** Total de páginas */
  public dataTotalPages = 0;
  /** Total de registros */
  public dataTotalSize = 0;
  /** Registros por página */
  public dataRowsPerPage = 5;

  showEventDetailsObj = null;

  get showEventDetails() {
    return this.showEventDetailsObj !== null;
  }

  set showEventDetails(v) {
    this.showEventDetailsObj = null;
  }

  /**
   * Ahora este componente recibe los datos sin paginar y se encarga de paginarlos
   * para que la experiencia de usuario no cambie.
   */
  @Watch("events")
  onEventsChange(newValue: Array<any>) {
    if (newValue.length > 0) {
      this.dataTotalPages = Math.ceil(newValue.length / this.dataRowsPerPage);
      this.dataTotalSize = newValue.length;
      this.dataPage = 1;
      this.dataEvents = newValue.slice(0, this.dataRowsPerPage);
    } else {
      this.dataEvents = [];
    }
  }

  showInfo(event) {
    this.showEventDetailsObj = event;
  }

  updatePage() {
    /*this.dataPage++;
    const newNumRows = this.dataPage * this.dataRowsPerPage;
    this.dataEvents = this.events.slice(0, newNumRows);*/
    let pageNumber = this.page + 1;
    this.$emit("loadMoreEvents", pageNumber);
  }
}
</script>
<style scoped>
.v-list {
  border: 1px solid #ccc;
  background: #fbf8f8;
  max-height: 225px;
  overflow-y: scroll;
  padding: 0;
}

div[role="listitem"]:not(:last-child) {
  border-bottom: 1px solid #ddd;
}
.date-label {
  display: block;
  color: #000;
}
.device-label {
  display: block;
  font-size: 12px;
}
</style>
