import {
  PairingLogBaseInterface,
  PairingLogSubscriptionInterface,
} from "./PairingInterface";
import PairingLogBaseEntity from "./PairingLogBaseEntity";

export default class PairingLogSubscriptionEntity extends PairingLogBaseEntity {
  public actionT: string;
  public extendedDays: number | undefined;
  public constructor(data: PairingLogSubscriptionInterface) {
    let requestedBy = "";
    try {
      requestedBy = JSON.parse(data.data).userEmailMavi;
    } catch (error) {
      requestedBy = "";
    }

    const pairingLog: PairingLogBaseInterface = {
      time: data.time,
      type: data.type,
      subtype: data.subtype,
      requestedBy: requestedBy,
      guestType: data.guestType,
    };
    super(pairingLog);
    this.actionT = this.generateActionT();
    try {
      this.extendedDays = JSON.parse(data.data).extendedDays;
    } catch (error) {
      this.extendedDays = undefined;
    }
  }

  /** Genera el código del texto de la acción para traducirlo. */
  private generateActionT(): string {
    if (this.isActivateTrial()) {
      return "deviceDetails.losSupport.actions.activateTrial";
    }
    if (this.isExtendTrial()) {
      return "deviceDetails.losSupport.actions.extendTrial";
    }
    return "";
  }
}
