import PairingBaseEntity from "./PairingBaseEntity";
import {
  PairingBaseInterface,
  PairingDeviceInterface,
} from "./PairingInterface";
import moment from "moment";

export default class PairingDeviceEntity extends PairingBaseEntity {
  public tag: string;
  public guest: boolean;
  public master: boolean;
  public status: string;
  public createdAt: string;
  public phoneModel: string;
  public phoneOS: string;
  public appBuild: string;
  public appVersion: string;
  private disabled: boolean;

  constructor(data: PairingDeviceInterface) {
    const superInterface: PairingBaseInterface = {
      id: data.id,
      userId: data.userId,
      userEmail: data.userEmail,
      type: data.type,
      deviceId: data.deviceId,
      subscriptionNoWifi: data.subscriptionNoWifi,
      guest: data.guest,
      master: data.master,
      logicalId: data.logicalId,
    };
    super(superInterface);
    this.tag = data.tag;
    this.guest = data.guest;
    this.master = data.master;
    this.status = data.status;
    this.createdAt = data.createdAt;
    this.phoneModel = data.phoneModel;
    this.phoneOS = data.phoneOS;
    this.appBuild = data.appBuild;
    this.appVersion = data.appVersion;
    this.disabled = data.isDisabled;
  }

  getCommercialName(language: string) {
    return super.getSubscriptionPlanName(language);
  }

  isMonitor(): boolean {
    return super.isMonitor();
  }
  isPhone(): boolean {
    return super.isPhone();
  }
  isGuardUnit(): boolean {
    return super.isGuardUnit();
  }
  isDisabled(): boolean {
    return this.disabled;
  }

  getType() {
    return super.getDeviceType();
  }

  public getNameIcon(): string {
    return super.getNameIcon();
  }

  getPhoneModel() {
    return this.phoneModel ? this.phoneModel : "-";
  }

  getPhoneOS() {
    return this.phoneOS ? this.phoneOS : "-";
  }

  getAppBuild() {
    return this.appBuild ? this.appBuild : "-";
  }

  getAppVersion() {
    return this.appVersion ? this.appVersion : "-";
  }

  /** Solo los dispositivos WIFI tienen identificador de plan de suscripción. */
  getPlanId(): number | undefined {
    return super.getPlanId();
  }

  /** Solo se puede eliminar un emparejamiento de propietario si tiene un plan es gratuito. */
  canDeleteMaster() {
    return super.isPlanFree();
  }

  /**
   * Obtiene la fecha de vencimiento de la suscripción en formato "YYYY-MM-DD".
   * Si no tiene fecha de vencimiento, devuelve "-".
   */
  public getExpiredDateFormatIso(): string {
    const date = super.getExpirationDate();
    return date ? moment(date).format("YYYY-MM-DD") : "-";
  }

  /**
   * Obtiene la fecha de vencimiento de la suscripción en formato "DD/MM/YYYY".
   * Si no tiene fecha de vencimiento, devuelve "-".
   */
  public getExpiredDateFormat(): string {
    const date = super.getExpirationDate();
    return date ? moment(date).format("DD/MM/YYYY") : "-";
  }

  public getFreeRenewedDateFormat(): string {
    const date = super.getFreeRenewedDate();
    return date ? moment(date).format("DD/MM/YYYY") : "-";
  }

  /**
   * Obtiene el precio formateado en formato "0,00 €".
   * Solo devuelve el precio si es un plan anual o mensual,
   * sino, devuelve "0,00 €".
   */
  public getPriceFormat(): string {
    const isPlanAoM = super.isPlanAnnual() || super.isPlanMonthly();
    const price = isPlanAoM ? super.getPrice() : 0;
    const currency = isPlanAoM ? super.getCurrency() : "EUR";

    return Intl.NumberFormat("es-ES", {
      style: "currency",
      currency: currency,
      minimumFractionDigits: 2,
    }).format(price);
  }

  /** Se puede activar un plan trial si el plan es gratuito. */
  public canActivateTrial(): boolean {
    return super.isPlanFree();
  }

  /** Se puede extender el periodo de prueba si el plan es de tipo trial. */
  public canExtendTrialPeriod(): boolean {
    return super.isPlanTrial();
  }

  /**
   * La fecha de suscripción solo se muestra si el plan es de pago.
   */
  public showSubscriptionStartDate(): boolean {
    return super.isPlanAnnual() || super.isPlanMonthly();
  }

  /**
   * Obtiene la fecha de emparejamiento en formato "DD/MM/YYYY".
   * Si no tiene fecha de renovación, devuelve "-".
   */
  public getStartDateFormat(): string {
    const date: Date | undefined = super.getCreateDate();
    return date ? moment(date).format("DD/MM/YYYY") : "-";
  }

  /**
   * Obtiene la fecha de creación de la suscripción en formato "DD/MM/YYYY".
   * Si no tiene fecha de expiración, devuelve "-".
   */
  public getCreateDateFormat(): string {
    const date: Date | undefined = super.getCreateDate();
    return date ? moment(date).format("DD/MM/YYYY") : "-";
  }
}
